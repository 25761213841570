import React, { FunctionComponent, PropsWithChildren } from "react";
import { Helmet } from "react-helmet";
import { Container, Spinner } from "react-bootstrap";

import HeaderRow from "./HeaderRow";

interface Props extends PropsWithChildren<any> {
  title: string;
  siteTitle: string;
  fullWith?: boolean;
  isLoading?: boolean;
}

const renderLoading = (
  <div className={"m-5 w-full text-center"}>
    <Spinner animation={"border"} />
  </div>
);

const renderTitle = (title: string, fullWith?: boolean): React.ReactNode => {
  if (fullWith) {
    return (
      <Container>
        <HeaderRow title={title} headingLevel={1} />
      </Container>
    );
  }

  return (
    <div>
      <HeaderRow title={title} headingLevel={1} />
    </div>
  );
};

export const ContentLayout: FunctionComponent<Props> = (props: Props) => {
  return (
    <Container fluid={props.fullWith} className={"h-100 py-5"}>
      <div>
        <Helmet>
          <title>{props.siteTitle}</title>
        </Helmet>
        {renderTitle(props.title, props.fullWith)}
        {props.isLoading && renderLoading}
        {!props.isLoading && props.children}
      </div>
    </Container>
  );
};
