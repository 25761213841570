import { UiSchema } from "@rjsf/core";

export const FormSettingsUiSchema: UiSchema = {
  formSchema: {
    formSchema: {
      "ui:widget": "textarea"
    },
    uiSchema: {
      "ui:widget": "textarea"
    }
  }
};
