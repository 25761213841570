import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import Form from "@rjsf/core";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import ButtonRow from "../common/button/ButtonRow";
import { BackButton, PrimaryButton } from "../common/button/Buttons";
import FirebaseService from "../../actions/firebase/FirebaseActions";
import { useAuthentication } from "../../hooks/useAuthentication";
import { createLimitEditorSchema } from "./CreateLimitEditorSchema";
import AddLimit from "../../models/limit/AddLimit";
import { trackEvent } from "../../helper/analytics";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

const UpdateLimitEditor: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  useAuthentication(props.isAuthenticated);
  const history = useHistory();

  const saveLimit = (formData: unknown): void => {
    const {
      property,
      limit: formLimit,
      price: formPrice
    } = formData as {
      property: string;
      limit: number;
      price: number;
    };
    const limit: AddLimit = {
      property: property,
      limit: formLimit,
      price: formPrice || 0
    };

    FirebaseService.addLimit(userId, limit)
      .then((limitId) => {
        toast.success("🎉 Die Änderungen wurden gespeichert");
        history.push(`/user/${userId}/limits/${limitId}`);
      })
      .catch((reason) => {
        console.log(reason);
        toast.error(
          "Es ist ein Fehler beim Speichern der Daten aufgetreten. Bitte laden Sie die Applikation erneut und versuchen Sie es noch einmal."
        );
      })
      .finally(() => trackEvent("create-limit"));
  };
  return (
    <Container className={"h-100 py-5"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spielgruppe Online - Angebot erstellen</title>
      </Helmet>
      <ButtonRow>
        <BackButton to={`/user/${userId}/limits`} />
        <h3>Angebot erstellen</h3>
      </ButtonRow>
      <div className="editor">
        <Form formData={{}} schema={createLimitEditorSchema} onSubmit={(e) => saveLimit(e.formData)}>
          <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
        </Form>
      </div>
    </Container>
  );
};

export default UpdateLimitEditor;
