import React, { FunctionComponent } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";

import FirebaseService from "../../actions/firebase/FirebaseActions";
import ButtonRow from "../common/button/ButtonRow";
import { BackButton } from "../common/button/Buttons";
import { useAuthentication } from "../../hooks/useAuthentication";
import RegistrationEditor from "./RegistrationEditor";
import { useFormSchema } from "../../hooks/useFormSchema";
import { trackEvent } from "../../helper/analytics";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}
const CreateRegistrationEditor: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  const [formSchema] = useFormSchema(userId);
  const history = useHistory();
  useAuthentication(props.isAuthenticated);

  const saveRegistration = async (formData: unknown): Promise<void> => {
    try {
      const registrationId = await FirebaseService.createRegistration(userId, formData);
      history.push(`/user/${userId}/registrations/${registrationId}`);
      toast.success("🎉 Die Änderungen wurden gespeichert");
    } catch (e) {
      console.log(e);
      toast.error(
        "Es ist ein Fehler beim Speichern der Daten aufgetreten. Bitte laden Sie die Applikation erneut und versuchen Sie es noch einmal."
      );
    } finally {
      trackEvent("create-registration");
    }
  };

  if (!formSchema) return <p>Daten werden geladen</p>;
  else {
    return (
      <Container className={"h-100 py-5"}>
        <Helmet>
          <title>Spielgruppe Online - Anmeldung bearbeiten</title>
        </Helmet>
        <Container>
          <ButtonRow>
            <BackButton to={`/user/${userId}/registrations`} />
          </ButtonRow>
          <h3>Anmeldung erstellen</h3>
          <div className="editor">
            <RegistrationEditor formSchema={formSchema} formData={{}} onSubmit={saveRegistration} />
          </div>
        </Container>
      </Container>
    );
  }
};

export default CreateRegistrationEditor;
