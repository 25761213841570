import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useAuthentication = (isAuthenticated: boolean): void => {
  const history = useHistory();

  useEffect(() => {
    const currentRoute = window.location.pathname;
    const params = window.location.search;
    if (!isAuthenticated) {
      history.push(`/?nextPage=${currentRoute}${params}`);
    }
  }, [isAuthenticated]);
};
