import { db } from "../../lib/firebase";
import { Subscription } from "../../models/userData/subscription/Subscription";

export async function getActiveSubscription(userId: string): Promise<Subscription | undefined> {
  const manualSubscription = await db
    .collection("users")
    .doc(userId)
    .collection("manualSubscriptions")
    .where("status", "in", ["trialing", "active"])
    .get();

  if (!manualSubscription.empty) {
    const subscriptionData = await manualSubscription.docs[0].data();
    return {
      role: subscriptionData.role,
      status: subscriptionData.status,
      manualSubscription: true
    };
  }

  const activeSubscriptions = await db
    .collection("users")
    .doc(userId)
    .collection("subscriptions")
    .where("status", "in", ["trialing", "active"])
    .get();

  if (activeSubscriptions.empty) {
    return undefined;
  }

  const subscriptionData = await activeSubscriptions.docs[0].data();

  return {
    role: subscriptionData.role,
    status: subscriptionData.status,
    manualSubscription: false
  };
}
