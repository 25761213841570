import { JSONSchema7 } from "json-schema";

export const updateLimitEditorSchema: JSONSchema7 = {
  type: "object",
  required: ["limit"],
  properties: {
    limit: {
      type: "number",
      title: "Anzahl der verfügbaren Plätze"
    },
    price: {
      type: "number",
      title: "Preis"
    }
  }
};
