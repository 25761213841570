import { useEffect, useState } from "react";
import firebase from "firebase/compat";

export const useAuthAdmin = (user: firebase.User | null): boolean => {
  const [isAdmin, setAdmin] = useState<boolean>();

  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      setAdmin(!!idTokenResult.claims.admin);
    });
  }, [user]);

  return isAdmin ?? false;
};
