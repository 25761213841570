import { JSONSchema7 } from "json-schema";

export const UpdatePasswordSchema: JSONSchema7 = {
  title: "Passwort ändern",
  description: "Hier können Sie das Passwort Ihres Accounts ändern.",
  type: "object",
  required: ["currentPassword", "password", "passwordConfirmation"],
  properties: {
    currentPassword: {
      type: "string",
      title: "Aktuelles Passwort"
    },
    password: {
      type: "string",
      title: "Passwort"
    },
    passwordConfirmation: {
      type: "string",
      title: "Passwort bestätigen"
    }
  }
};
