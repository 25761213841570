import { JSONSchema7 } from "json-schema";

export const EmailSettingsSchema: JSONSchema7 = {
  description:
    "Hier können Sie festlegen, ob bei erfolgreicher Anmeldung ein Bestätigungs-E-Mail an Sie und an Ihren Kunden " +
    "versendet wird und wie diese E-Mails aussehen sollen. " +
    "Erweiterte Gestaltungsmöglichkeiten sind nur mit Spielgruppe Plus verfügbar. ",
  type: "object",
  required: [],
  properties: {
    mailActive: {
      type: "boolean",
      title: "E-Mail-Versand aktivieren"
    },
    confirmationEmailAddress: {
      type: "string",
      title: "E-Mail-Adresse, an welche die Bestätigungen der Anmeldungen gesendet werden."
    },
    confirmationSubject: {
      type: "string",
      title: "Betreff der Bestätigungs-E-Mails an Sie"
    },
    confirmationTemplate: {
      type: "string",
      title: "Textvorlage der E-Mails an Sie (Formulardaten verwenden: {{{ formData }}} )"
    },
    customerConfirmationSubject: {
      type: "string",
      title: "Betreff der Bestätigungs-E-Mails an den Kunden"
    },
    customerConfirmationTemplate: {
      type: "string",
      title: "Textvorlage der E-Mails an den Kunden (Formulardaten verwenden: {{{ formData }}} )"
    }
  }
};
