import { useState, useEffect } from "react";

import { Subscription } from "../models/userData/subscription/Subscription";
import { getActiveSubscription } from "../actions/subscription/getActiveSubscription";

export default (userId: string | undefined): Subscription | undefined => {
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    if (userId) {
      const handleSubscription = async function (): Promise<void> {
        setSubscription(await getActiveSubscription(userId));
      };
      handleSubscription();
    }
  }, [userId]);

  return subscription;
};
