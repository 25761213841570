import { useEffect, useState } from "react";

import FirebaseService from "../actions/firebase/FirebaseActions";
import Limit from "../models/limit/Limit";
import { FirebaseSnapshotMapper } from "../actions/firebase/FirebaseSnapshotMapper";

export const useLimit = (userId: string, limitId: string): [Limit | undefined, boolean, boolean] => {
  const [registration, setLimit] = useState<Limit>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const unsubscribe = FirebaseService.streamLimit(userId, limitId, {
      next: (documentSnapshot) => {
        const updatedLimit = FirebaseSnapshotMapper.toLimit(documentSnapshot);
        setLimit(updatedLimit);
        setLoading(false);
      },
      error: () => {
        setError(true);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [userId, limitId]);

  return [registration, isLoading, isError];
};
