import dayjs from "dayjs";
import * as _ from "lodash";

import Registration from "../models/registration/Registration";
import Limit from "../models/limit/Limit";

function isDateValue(value: any): boolean {
  if (!(typeof value === "string")) {
    return false;
  }

  return /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d+([+-][0-2]\d:[0-5]\d|)/.test(value);
}

function isPrimitive(value: any): boolean {
  return value !== Object(value);
}

export function getFormattedRegistrations(registrations: Registration[]): Registration[] {
  const formattedRegistrations = _.cloneDeep(registrations);
  return formattedRegistrations.map((registration) => {
    for (const key in registration?.formData) {
      const value = registration.formData[key];
      if (!isPrimitive(value)) {
        registration.formData[key] = "";
      } else if (typeof value === "boolean") {
        registration.formData[key] = value ? "Ja" : "";
      } else if (isDateValue(value)) {
        registration.formData[key] = dayjs(value).locale("de").format("DD. MMMM YYYY");
      } else {
        registration.formData[key] = value;
      }
    }
    return registration;
  });
}

export function getExportRegistrations(registrations: Registration[], limits: Limit[]): Registration[] {
  return registrations.map((registration) => {
    const price = limits
      .map((limit) => (registration.formData[limit.property] && limit.price ? limit.price : 0))
      .reduce((total, currentValue) => total + currentValue, 0);
    registration.formData["Preis"] = price;
    return registration;
  });
}
