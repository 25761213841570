import React, { FunctionComponent, ReactElement } from "react";
import { Card } from "react-bootstrap";

interface Props {
  children: ReactElement;
  title: string;
  subTitle?: string;
}

const DashboardCard: FunctionComponent<Props> = ({ children, title, subTitle }: Props) => {
  return (
    <Card className={"w-100"}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        {subTitle ? <Card.Subtitle className="mb-2 text-muted">{subTitle}</Card.Subtitle> : null}
        {children}
      </Card.Body>
    </Card>
  );
};

export default DashboardCard;
