import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import React, { FunctionComponent } from "react";

import Registration from "../../models/registration/Registration";

interface NumberOfRegistrationsChartData {
  name: string;
  Anmeldungen: number;
  pv: number;
}

interface Props {
  registrations: Registration[];
}

export const NumberOfRegistrationsChart: FunctionComponent<Props> = ({ registrations }: Props) => {
  const chartData = mapToChartData(registrations);

  return (
    <ResponsiveContainer width={"100%"} height={400}>
      <AreaChart
        data={chartData}
        margin={{
          top: 10,
          right: 10,
          left: 0,
          bottom: 0
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="Anmeldungen" stroke="#1a9cb5" fill="#1a9cb5" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

function mapToChartData(registrations: Registration[]): NumberOfRegistrationsChartData[] {
  const sortedRegistrations = registrations.sort(
    (a, b) => a.creation.createdAt.getTime() - b.creation.createdAt.getTime()
  );

  if (sortedRegistrations.length === 0) {
    return [];
  }

  const dates = getDatesInRange(sortedRegistrations[0].creation.createdAt, new Date());

  let currentNumberOfRegistrations = 0;
  return dates.map((date, index) => {
    const registrationsPerDate = registrations.filter(
      (value) => value.creation.createdAt.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)
    );
    currentNumberOfRegistrations += registrationsPerDate.length;
    return {
      name: date.toLocaleDateString(),
      Anmeldungen: currentNumberOfRegistrations,
      pv: index
    };
  });
}

function getDatesInRange(start: Date, end: Date): Date[] {
  const dates: Date[] = [];
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    dates.push(new Date(dt));
  }
  return dates;
}
