import { FirebaseApi } from "./FirebaseApi";

class Apis {
  private readonly createRegistrationApi: string;
  private readonly updateRegistrationApi: string;
  private readonly deleteRegistrationApi: string;
  private readonly deleteAllRegistrationApi: string;

  constructor(
    createRegistrationApi?: string,
    updateRegistrationApi?: string,
    deleteRegistrationApi?: string,
    deleteAllRegistrationApi?: string
  ) {
    this.createRegistrationApi = Apis.throwIfFalsy(createRegistrationApi);
    this.updateRegistrationApi = Apis.throwIfFalsy(updateRegistrationApi);
    this.deleteRegistrationApi = Apis.throwIfFalsy(deleteRegistrationApi);
    this.deleteAllRegistrationApi = Apis.throwIfFalsy(deleteAllRegistrationApi);
  }

  public getFirebaseApi(): FirebaseApi {
    return new FirebaseApi(
      this.createRegistrationApi,
      this.updateRegistrationApi,
      this.deleteRegistrationApi,
      this.deleteAllRegistrationApi
    );
  }

  private static throwIfFalsy(value?: string): string {
    if (!value) {
      throw new Error("Registration API is not defined");
    }
    return value;
  }
}

export default new Apis(
  process.env.REACT_APP_POST_REGISTRATION_API,
  process.env.REACT_APP_PUT_REGISTRATION_API,
  process.env.REACT_APP_DELETE_REGISTRATION_API,
  process.env.REACT_APP_DELETE_ALL_REGISTRATION_API
);
