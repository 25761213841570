import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";
import { Form } from "@rjsf/bootstrap-4";
import { Helmet } from "react-helmet";

import { PrimaryButton } from "../../common/button/Buttons";
import { FormSettingsSchema } from "./FormSettingsSchema";
import { FormSettingsUiSchema } from "./FormSettingsUiSchema";
import { FormSettings as FormSettingsModel } from "../../../models/userData/settings/FormSettings";
import FirebaseService from "../../../actions/firebase/FirebaseActions";
import { RawFormSettings } from "../../../models/userData/settings/RawFormSettings";
import { trackEvent } from "../../../helper/analytics";

interface Props {
  userId: string;
  formSettings?: FormSettingsModel;
}

export const FormSettings: FunctionComponent<Props> = ({ formSettings, userId }: Props) => {
  if (!formSettings) {
    return <p>Formular-Einstellungen konnten nicht geladen werden. Bitte wenden Sie sich an den Support.</p>;
  }

  const rawFormSettings: RawFormSettings = {
    formSchema: {
      formSchema: JSON.stringify(formSettings.formSchema?.formSchema),
      uiSchema: JSON.stringify(formSettings.formSchema?.uiSchema),
      emailConfirmationField: formSettings.formSchema?.emailConfirmationField
    },
    formSchemaEndpoint: formSettings.formSchemaEndpoint
  };

  const updateFormSettings = (formData: unknown): void => {
    const formSettingsFormData = formData as RawFormSettings;
    try {
      FirebaseService.updateFormSettings(userId, formSettingsFormData)
        .then(() => {
          toast.success("Die Einstellungen wurden erfolgreich gespeichert.");
        })
        .catch((error) => {
          toast.error(`Fehler: ${error}`);
        });
    } catch (e) {
      toast.error(
        `Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support: Fehler: ${e}`
      );
    } finally {
      trackEvent("update-form-settings-admin");
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Webtie Anmeldungen - Formulareinstellungen</title>
      </Helmet>
      <Form
        uiSchema={FormSettingsUiSchema}
        schema={FormSettingsSchema}
        formData={rawFormSettings}
        onSubmit={(e: any) => updateFormSettings(e.formData)}>
        <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
      </Form>
    </div>
  );
};
