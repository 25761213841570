import { FunctionComponent, useState } from "react";
import firebase from "firebase/compat/app";
import { Alert, Button, Spinner } from "react-bootstrap";

interface Props {
  user?: firebase.User | null;
  className?: string;
}
export const EmailVerifiedNotification: FunctionComponent<Props> = ({ user, className }: Props) => {
  const [mailSent, setMailSent] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  if (!user) {
    return <></>;
  }

  const emailVerified = user.emailVerified;

  async function sendEmailVerification(): Promise<void> {
    setLoading(true);
    await user?.sendEmailVerification();
    setMailSent(true);
    setLoading(false);
  }

  return (
    <div className={className}>
      {!emailVerified && (
        <Alert variant={"info"}>
          Bitte bestätigen Sie Ihre E-Mail-Adresse, indem Sie auf den Link klicken, welchen wir Ihnen per E-Mail
          zugestellt haben.
          <br /> Haben Sie keine E-Mail erhalten?
          <Button disabled={isLoading} variant={"link"} onClick={() => sendEmailVerification()}>
            {isLoading ? <Spinner animation={"border"} size={"sm"} /> : "Bestätigungs-E-Mail anfordern"}
          </Button>
          {mailSent && (
            <>
              <br />
              Das E-Mail wurde versendet.
            </>
          )}
        </Alert>
      )}
    </div>
  );
};
