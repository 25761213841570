import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";
import { Form } from "@rjsf/bootstrap-4";
import { Helmet } from "react-helmet";

import FirebaseService from "../../../../actions/firebase/FirebaseActions";
import { PrimaryButton } from "../../../common/button/Buttons";
import { UpdatePasswordUiSchema } from "./UpdatePasswordUiSchema";
import { UpdatePasswordSchema } from "./UpdatePasswordSchema";

interface Props {
  userId: string;
}
const AccountPasswordSettings: FunctionComponent<Props> = () => {
  const updatePassword = (formData: unknown): void => {
    const { currentPassword, password, passwordConfirmation } = formData as {
      currentPassword: string;
      password: string;
      passwordConfirmation: string;
    };

    if (password !== passwordConfirmation) {
      toast.error("Die eingegeben Passwörter stimmen nicht überein.");
      return;
    }

    FirebaseService.updatePassword(currentPassword, password)
      .then(() => {
        toast.success("Das neue Passwort wurde erfolgreich gesetzt.");
      })
      .catch((error) => {
        toast.error(`Fehler: ${error}`);
      });
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Webtie Anmeldungen - Profil - Passwort anpassen</title>
      </Helmet>
      <Form
        uiSchema={UpdatePasswordUiSchema}
        schema={UpdatePasswordSchema}
        onSubmit={(e: any) => updatePassword(e.formData)}>
        <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
      </Form>
    </div>
  );
};

export default AccountPasswordSettings;
