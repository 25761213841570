import React, { FunctionComponent, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { Container, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSortAlphaDown, faSortAlphaUp, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import ExportAsCsvButton from "./ExportAsCsvButton";
import ButtonRow from "../common/button/ButtonRow";
import { DeleteButton, PrimaryButton } from "../common/button/Buttons";
import { useRegistrations } from "../../hooks/useRegistrations";
import { useSchemaProperties } from "../../hooks/useSchemaProperties";
import { useLimits } from "../../hooks/useLimits";
import FirebaseService from "../../actions/firebase/FirebaseActions";
import Registration from "../../models/registration/Registration";
import { useAuthentication } from "../../hooks/useAuthentication";
import useSubscriptionType from "../../hooks/useSubscriptionType";
import { ContentLayout } from "../common/Layout";
import "dayjs/locale/de";
import { getExportRegistrations, getFormattedRegistrations } from "../../helper/getFormattedRegistrations";

dayjs.locale("de");

const StickyTable = styled(Table)`
  th:last-child,
  td:last-child {
    position: sticky;
    right: 0;
    background-color: white;
  }

  tr:nth-child(odd) > td:last-child {
    background-color: rgb(242, 242, 242);
  }
`;

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

const Registrations: FunctionComponent<Props> = (props: Props) => {
  type SortDirection = "ASCENDING" | "DESCENDING" | "UNSORTED";
  const [sortProperty, setSortProperty] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("UNSORTED");
  const [buttonsDisabled, disableButtons] = useState<boolean>(false);

  const userId = props.match.params.userId;
  const history = useHistory();
  useAuthentication(props.isAuthenticated);

  const [registrations, registrationsLoading, registrationsError] = useRegistrations(userId);
  const [limits, limitsLoading, limitsError] = useLimits(userId);
  const [schemaProperties, schemaPropertiesLoading, schemaPropertiesError] = useSchemaProperties(userId);
  const subscriptionType = useSubscriptionType(userId);

  const isLoading = registrationsLoading || schemaPropertiesLoading || limitsLoading;
  const isError = registrationsError || schemaPropertiesError || limitsError;

  const renderSortIcon = (property: string): React.ReactNode => {
    if (!property || sortProperty !== property) {
      return <></>;
    }
    if (sortDirection === "ASCENDING") {
      return <FontAwesomeIcon style={{ marginLeft: 20, color: "#1991ac" }} icon={faSortAlphaDown} />;
    } else if (sortDirection === "DESCENDING") {
      return <FontAwesomeIcon style={{ marginLeft: 20, color: "#1991ac" }} icon={faSortAlphaUp} />;
    } else {
      return <></>;
    }
  };

  const handleHeaderClick = (property: string): void => {
    setSortProperty(property);
    if (property === sortProperty) {
      if (sortDirection === "ASCENDING") {
        setSortDirection("DESCENDING");
      } else if (sortDirection === "DESCENDING") {
        setSortDirection("UNSORTED");
      } else if (sortDirection === "UNSORTED") {
        setSortDirection("ASCENDING");
      } else {
        setSortDirection("UNSORTED");
      }
    } else {
      setSortDirection("ASCENDING");
    }
  };

  const sortRegistrations = (sortedRegistrations: Registration[]): void => {
    if (sortProperty !== "") {
      if (sortDirection === "ASCENDING") {
        sortedRegistrations.sort((a, b) => {
          if (a.formData[sortProperty] < b.formData[sortProperty]) return -1;
          if (a.formData[sortProperty] > b.formData[sortProperty]) return 1;
          return 0;
        });
      } else if (sortDirection === "DESCENDING") {
        sortedRegistrations.sort((a, b) => {
          if (a.formData[sortProperty] > b.formData[sortProperty]) return -1;
          if (a.formData[sortProperty] < b.formData[sortProperty]) return 1;
          return 0;
        });
      } else {
        setSortProperty("");
      }
    }
  };

  if (isError) return <p>Fehler beim Laden der Daten</p>;
  else {
    const limitProperties = limits?.map((limit) => limit.property);
    const sortedRegistrations = _.cloneDeep(registrations);

    sortRegistrations(sortedRegistrations);
    const formattedRegistration = getFormattedRegistrations(sortedRegistrations);
    const registrationsForExport = getExportRegistrations(formattedRegistration, limits);

    return (
      <ContentLayout
        isLoading={isLoading}
        title={"Anmeldungen"}
        siteTitle={"Spielgruppe Online - Anmeldungen"}
        fullWith={true}>
        <Container>
          <ButtonRow>
            <PrimaryButton
              className={"mr-1 mt-1 mt-md-0"}
              disabled={buttonsDisabled}
              onClick={() => {
                history.push(`/user/${userId}/registrations/create`);
              }}>
              Neue Anmeldung
            </PrimaryButton>
            <ExportAsCsvButton
              className={"mr-1 mt-1 mt-md-0"}
              properties={schemaProperties}
              registrations={registrationsForExport}
              limits={limits}
              subscriptionType={subscriptionType}
            />
            <DeleteButton
              disabled={buttonsDisabled}
              className={"mt-1 mt-md-0"}
              onClick={() => {
                disableButtons(true);
                if (
                  window.confirm(
                    "Wir empfehlen Ihnen, vor dem Löschen alle Anmeldungen zu exportieren. " +
                      "Wenn Sie fortfahren werden alle Anmeldungen unwiderruflich gelöscht. " +
                      "Möchten Sie fortfahren?"
                  )
                ) {
                  FirebaseService.deleteAllRegistrations(userId)
                    .then(() => toast.success("Alle Anmeldungen wurden erfolgreich gelöscht"))
                    .catch(() =>
                      toast.error(
                        "Fehler beim Löschen der Anmeldungen aufgetreten. Bitte versuchen Sie es später erneut."
                      )
                    )
                    .finally(() => disableButtons(false));
                }
              }}>
              Alle Anmeldungen löschen
            </DeleteButton>
          </ButtonRow>
        </Container>

        <StickyTable responsive striped bordered hover size={"xl"} className={"registrations-table"}>
          <thead>
            <tr>
              {schemaProperties.map((property) => {
                return (
                  <th key={property} onClick={() => handleHeaderClick(property)}>
                    {property} {renderSortIcon(property)}
                  </th>
                );
              })}
              <th>Gewählte Angebote</th>
              <th>Gesamtpreis</th>
              <th
                onClick={() => {
                  setSortProperty("");
                }}>
                Zeitpunkt
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formattedRegistration.map((registration, index) => {
              return (
                <tr key={index}>
                  {schemaProperties.map((property) => (
                    <td key={property}>{registration.formData[property]}</td>
                  ))}
                  <td>
                    <p className={"mb-0"}>
                      {limitProperties
                        ?.map((property) => (registration.formData[property] ? property : undefined))
                        .filter((property) => property !== undefined)
                        .map((property) => (
                          <span key={property}>
                            {property} <br />
                          </span>
                        ))}
                    </p>
                  </td>
                  <td>
                    CHF{" "}
                    {limits
                      .map((limit) => (registration.formData[limit.property] && limit.price ? limit.price : 0))
                      .reduce((total, currentValue) => total + currentValue, 0)}
                  </td>
                  <td>
                    {registration.creation.createdAt.toLocaleDateString()}
                    {"  "}
                    {registration.creation.createdAt.toLocaleTimeString()}
                  </td>
                  <td>
                    <OverlayTrigger
                      transition={false}
                      key={"edit"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip className={"webtie-tooltip"} id={"tooltip-admin"}>
                          Bearbeiten
                        </Tooltip>
                      }>
                      <PrimaryButton
                        disabled={buttonsDisabled}
                        className={"mr-1"}
                        size={"sm"}
                        onClick={() => {
                          history.push(`/user/${userId}/registrations/${registration.id}`);
                        }}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </PrimaryButton>
                    </OverlayTrigger>
                    <OverlayTrigger
                      transition={false}
                      key={"delete"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip className={"webtie-tooltip"} id={"tooltip-admin"}>
                          Löschen
                        </Tooltip>
                      }>
                      <DeleteButton
                        disabled={buttonsDisabled}
                        size={"sm"}
                        onClick={() => {
                          if (window.confirm("Anmeldung wirklich löschen?")) {
                            disableButtons(true);
                            FirebaseService.deleteRegistration(userId, registration.id)
                              .catch(() =>
                                toast.error(
                                  "Die Anmeldung konnte nicht gelöscht werden. Bitte versuchen Sie es später nochmals."
                                )
                              )
                              .finally(() => disableButtons(false));
                          }
                        }}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </DeleteButton>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StickyTable>
      </ContentLayout>
    );
  }
};

export default Registrations;
