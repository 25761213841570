import React, { ErrorInfo } from "react";

interface GlobalErrorBoundaryState {
  hasError: boolean;
}

interface Props {
  children?: JSX.Element;
}

export class GlobalErrorBoundary extends React.Component<Props, GlobalErrorBoundaryState> {
  constructor(props: Record<string, never> | Readonly<Record<string, never>>) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: Error): GlobalErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): JSX.Element | undefined {
    if (this.state.hasError) {
      return (
        <>
          <h1>Ein Fehler ist aufgetreten.</h1>
          <p>
            Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut, indem Sie die Seite neu laden.
          </p>
        </>
      );
    }

    return this.props.children;
  }
}
