import { useEffect, useState } from "react";

import FirebaseService from "../actions/firebase/FirebaseActions";
import { FirebaseSnapshotMapper } from "../actions/firebase/FirebaseSnapshotMapper";
import { User } from "../models/user/User";

export const useUsers = (): [User[], boolean, boolean?] => {
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = FirebaseService.streamUsers({
      next: (querySnapshot) => {
        const updatedRegistrations = FirebaseSnapshotMapper.toUsers(querySnapshot);
        setUsers(updatedRegistrations);
        setLoading(false);
      },
      error: () => {
        setError(true);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [setUsers, setLoading]);

  return [users, isLoading, error];
};
