import { useEffect, useState } from "react";

import FirebaseService from "../actions/firebase/FirebaseActions";
import { FirebaseSnapshotMapper } from "../actions/firebase/FirebaseSnapshotMapper";
import { UserData } from "../models/userData/UserData";

export const useUserData = (userId: string): [UserData | undefined, boolean, boolean] => {
  const [userData, setUserData] = useState<UserData>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const unsubscribe = FirebaseService.streamUserData(userId, {
      next: (documentSnapshot) => {
        const updatedUserData = FirebaseSnapshotMapper.toUserData(documentSnapshot);
        setUserData(updatedUserData);
        setLoading(false);
      },
      error: () => {
        setError(true);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [userId]);

  return [userData, isLoading, isError];
};
