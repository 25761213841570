import { UiSchema, WidgetProps } from "@rjsf/core";

import { WysiwygFormWidget } from "../settings/widget/WysiwygFormWidget";

export const EmailSettingsUiSchema: UiSchema = {
  confirmationMailAddress: {
    "ui:widget": "email"
  },
  confirmationTemplate: {
    "ui:widget": (props: WidgetProps) => <WysiwygFormWidget {...props} />
  },
  customerConfirmationTemplate: {
    "ui:widget": (props: WidgetProps) => <WysiwygFormWidget {...props} />
  }
};
