import React, { FunctionComponent } from "react";
import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { SpielgruppePlusEditorToolbarConfig } from "./SpielgruppePlusEditorToolbarConfig";
import "./WysiwygFormWidgetStyles.css";
import { FreeEditorToolbarConfig } from "./FreeEditorToolbarConfig";
import useSubscriptionType from "../../../hooks/useSubscriptionType";
import { useAuthUser } from "../../../hooks/useAuthUser";

export interface WysiwygFormWidgetProps {
  id: string;
  label: string;
  onChange: (value: string) => void;
  value?: string;
}

export const WysiwygFormWidget: FunctionComponent<WysiwygFormWidgetProps> = (props: WysiwygFormWidgetProps) => {
  const authUser = useAuthUser();
  const subscriptionType = useSubscriptionType(authUser?.uid);

  const toRawDraftContentState = (html: string): RawDraftContentState => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return convertToRaw(contentState);
  };

  const toHtml = (contentState: RawDraftContentState): string => {
    return draftToHtml(contentState);
  };

  const onContentStateChanged = (editorState: RawDraftContentState): void => {
    const html = toHtml(editorState);
    props.onChange(html);
  };

  const freeEditor = (
    <Editor
      initialContentState={toRawDraftContentState(props.value ?? "")}
      wrapperClassName="email-editor-wrapper"
      editorClassName="email-editor"
      onContentStateChange={onContentStateChanged}
      toolbar={FreeEditorToolbarConfig}
    />
  );

  const spielgruppePlusEditor = (
    <Editor
      initialContentState={toRawDraftContentState(props.value ?? "")}
      wrapperClassName="email-editor-wrapper"
      editorClassName="email-editor"
      onContentStateChange={onContentStateChanged}
      toolbar={SpielgruppePlusEditorToolbarConfig}
    />
  );

  return (
    <div>
      <label className={"form-label"}>{props.label}</label>
      {subscriptionType && subscriptionType === "Spielgruppe Plus" && spielgruppePlusEditor}
      {subscriptionType !== "Spielgruppe Plus" && freeEditor}
    </div>
  );
};
