import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";

import FirebaseService from "../../actions/firebase/FirebaseActions";
import ButtonRow from "../common/button/ButtonRow";
import { UpdateRegistration } from "../../models/registration/Registration";
import { BackButton } from "../common/button/Buttons";
import { useRegistration } from "../../hooks/useRegistration";
import { useAuthentication } from "../../hooks/useAuthentication";
import RegistrationEditor from "./RegistrationEditor";
import { useFormSchema } from "../../hooks/useFormSchema";
import { trackEvent } from "../../helper/analytics";

interface MatchParams {
  userId: string;
  registrationId: string;
}
interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}
const UpdateRegistrationEditor: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  const registrationId = props.match.params.registrationId;

  const [registration, registrationLoading, registrationError] = useRegistration(userId, registrationId);
  const [formSchema, fetchFormSchema] = useFormSchema(userId);
  useAuthentication(props.isAuthenticated);

  const saveRegistration = async (formData: unknown): Promise<void> => {
    const registration: UpdateRegistration = {
      id: registrationId,
      formData: formData
    };

    try {
      await FirebaseService.updateRegistration(userId, registration);
      toast.success("🎉 Die Änderungen wurden gespeichert");
      fetchFormSchema();
    } catch (e) {
      console.log(e);
      toast.error(
        "Es ist ein Fehler beim Speichern der Daten aufgetreten. Bitte laden Sie die Applikation erneut und versuchen Sie es noch einmal."
      );
    } finally {
      trackEvent("update-registration");
    }
  };

  if (registrationLoading || !formSchema) return <p>Daten werden geladen</p>;
  else if (registrationError || !registration) return <p>Fehler beim Laden der Daten</p>;
  else {
    return (
      <Container className={"h-100 py-5"}>
        <Helmet>
          <title>Spielgruppe Online - Anmeldung bearbeiten</title>
        </Helmet>
        <ButtonRow>
          <BackButton to={`/user/${userId}/registrations`} />
        </ButtonRow>
        <h3>Anmeldung bearbeiten</h3>
        <div className="editor">
          <RegistrationEditor formSchema={formSchema} formData={registration.formData} onSubmit={saveRegistration} />
          <div className="pt-3">
            <p>Erstellt: {registration.creation.createdAt.toLocaleString()}</p>
            <p>Zuletzt aktualisiert: {registration.creation.updatedAt.toLocaleString()}</p>
          </div>
        </div>
      </Container>
    );
  }
};

export default UpdateRegistrationEditor;
