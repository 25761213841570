import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";

interface Props {
  title: string;
  text?: string;
  headingLevel: number;
  className?: string;
}

const HeaderRow: FunctionComponent<Props> = ({ title, text, headingLevel = 1, className }: Props) => {
  const Tag = `h${headingLevel}` as keyof JSX.IntrinsicElements;

  return (
    <Row>
      <Col>
        <Tag className={className || ""}>{title}</Tag>
        {text ? <p>{text}</p> : null}
      </Col>
    </Row>
  );
};

export default HeaderRow;
