import { useEffect, useState } from "react";

import Registration from "../models/registration/Registration";
import FirebaseService from "../actions/firebase/FirebaseActions";
import { FirebaseSnapshotMapper } from "../actions/firebase/FirebaseSnapshotMapper";

export const useRegistration = (
  userId: string,
  registrationId: string
): [Registration | undefined, boolean, boolean] => {
  const [registration, setRegistration] = useState<Registration>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const unsubscribe = FirebaseService.streamRegistration(userId, registrationId, {
      next: (documentSnapshot) => {
        const updatedRegistration = FirebaseSnapshotMapper.toRegistration(documentSnapshot);
        setRegistration(updatedRegistration);
        setLoading(false);
      },
      error: () => {
        setError(true);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [userId, registrationId]);

  return [registration, isLoading, isError];
};
