import { JSONSchema7 } from "json-schema";

export const UpdateEmailSchema: JSONSchema7 = {
  title: "E-Mail-Adresse ändern",
  description: "Hier können Sie die E-Mail-Adresse Ihres Accounts ändern.",
  type: "object",
  required: ["currentEmail", "currentPassword", "newEmail"],
  properties: {
    currentEmail: {
      type: "string",
      title: "Aktuelle Emailadresse"
    },
    currentPassword: {
      type: "string",
      title: "Aktuelles Passwort"
    },
    newEmail: {
      type: "string",
      title: "Neue Emailadresse"
    }
  }
};
