import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { Col, Row, Tab, Tabs } from "react-bootstrap";

import AccountPasswordSettings from "./account/password/AccountPasswordSettings";
import { useUserData } from "../../hooks/useUserData";
import { useAuthentication } from "../../hooks/useAuthentication";
import { FormSettings } from "./formadmin/FormSettings";
import AccountEmailSettings from "./account/email/AccountEmailSettings";
import { ContentLayout } from "../common/Layout";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

export const Settings: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  const [userData, isLoading, isError] = useUserData(userId);
  useAuthentication(props.isAuthenticated);

  if (isError) return <p>Fehler beim Laden der Daten</p>;
  return (
    <ContentLayout title={"Einstellungen"} siteTitle={"Spielgruppe Online - Einstellungen"} isLoading={isLoading}>
      <Row className={"py-5"}>
        <Col>
          <Tabs defaultActiveKey="default" id="uncontrolled-tab-example" className="mb-5">
            <Tab eventKey={"default"} title={"Passwort"}>
              <AccountPasswordSettings userId={userId} />
            </Tab>
            <Tab eventKey="account-email" title="E-Mail">
              <AccountEmailSettings userId={userId} />
            </Tab>
            {props.isAdmin && (
              <Tab eventKey="form" title="Formular (admin)">
                <FormSettings userId={userId} formSettings={userData?.settings?.formSettings} />
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>
    </ContentLayout>
  );
};
