import React, { FunctionComponent, useEffect, useState } from "react";
import { Container, Nav, Navbar as BootstrapNavbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  faAlignJustify,
  faCog,
  faEnvelope,
  faEnvelopeOpenText,
  faHome,
  faPowerOff,
  faPuzzlePiece,
  faUserShield
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import WebtieLogo from "../webtie_logo.svg";
import "./navbarStyle.scss";
import { SpielgruppePlusIcon } from "../SpielgruppePlusIcon";

interface Props {
  userId: string;
  isAdmin: boolean;
  signOut: () => Promise<void>;
}

const Navbar: FunctionComponent<Props> = ({ userId, isAdmin, signOut }: Props) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width < 1200;
  const history = useHistory();

  const updateDimensions = (): void => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <BootstrapNavbar bg="light" expand="xl" className={"mb-2"}>
      <Container>
        <Nav.Link className={"pl-0"} as={Link} to={`/user/${userId}/dashboard`}>
          <BootstrapNavbar.Brand>
            <img src={WebtieLogo} height={45} alt={"Webtie Logo"} />
          </BootstrapNavbar.Brand>
        </Nav.Link>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-0 pt-3 pt-lg-0 mr-auto text-right text-lg-left">
            <Nav.Link
              active={pathname === `/user/${userId}/dashboard`}
              className={"mr-lg-3 my-1 my-lg-0"}
              as={Link}
              to={`/user/${userId}/dashboard`}>
              {isMobile && "Dashboard"}{" "}
              <FontAwesomeIcon icon={faHome} style={isMobile ? { marginLeft: 5 } : {}} className={"mr-lg-1"} />{" "}
              {!isMobile && "Dashboard"}
            </Nav.Link>

            <Nav.Link
              active={pathname === `/user/${userId}/registrations`}
              className={"mr-lg-3 my-1 my-lg-0"}
              as={Link}
              to={`/user/${userId}/registrations`}>
              {isMobile && "Anmeldungen"}
              <FontAwesomeIcon
                icon={faEnvelopeOpenText}
                style={isMobile ? { marginLeft: 5 } : {}}
                className={"mr-lg-1"}
              />{" "}
              {!isMobile && "Anmeldungen"}
            </Nav.Link>
            <Nav.Link
              active={pathname === `/user/${userId}/limits`}
              className={"mr-lg-3 my-1 my-lg-0"}
              as={Link}
              to={`/user/${userId}/limits`}>
              {isMobile && "Angebot"}{" "}
              <FontAwesomeIcon icon={faPuzzlePiece} style={isMobile ? { marginLeft: 5 } : {}} className={"mr-lg-1"} />{" "}
              {!isMobile && "Angebot"}
            </Nav.Link>
            <Nav.Link
              active={pathname === `/user/${userId}/form`}
              className={"mr-lg-3 my-1 my-lg-0"}
              as={Link}
              to={`/user/${userId}/form`}>
              {isMobile && "Formular"}{" "}
              <FontAwesomeIcon icon={faAlignJustify} style={isMobile ? { marginLeft: 5 } : {}} className={"mr-lg-1"} />{" "}
              {!isMobile && "Formular"}
            </Nav.Link>
            <Nav.Link
              active={pathname === `/user/${userId}/email`}
              className={"mr-lg-3 my-1 my-lg-0"}
              as={Link}
              to={`/user/${userId}/email`}>
              {isMobile && "E-Mail"}{" "}
              <FontAwesomeIcon icon={faEnvelope} style={isMobile ? { marginLeft: 5 } : {}} className={"mr-lg-1"} />{" "}
              {!isMobile && "E-Mail"}
            </Nav.Link>
          </Nav>
          <Nav className={"text-right text-lg-left"}>
            {isAdmin ? (
              <>
                <OverlayTrigger
                  transition={false}
                  key={"admin"}
                  placement={"bottom"}
                  overlay={
                    <Tooltip className={"webtie-tooltip"} id={"tooltip-admin"}>
                      Admin
                    </Tooltip>
                  }>
                  {({ ref, ...triggerHandler }) => (
                    <Nav.Link
                      className={"my-1 my-lg-0"}
                      active={pathname === `/user/${userId}/admin`}
                      as={Link}
                      to={`/user/${userId}/admin`}
                      ref={ref}
                      {...triggerHandler}>
                      {isMobile && "Admin"}{" "}
                      <FontAwesomeIcon icon={faUserShield} style={isMobile ? { marginLeft: 5 } : {}} />
                    </Nav.Link>
                  )}
                </OverlayTrigger>
              </>
            ) : (
              <></>
            )}
            <OverlayTrigger
              transition={false}
              key={"settings"}
              placement={"bottom"}
              overlay={
                <Tooltip className={"webtie-tooltip"} id={"tooltip-settings"}>
                  Einstellungen
                </Tooltip>
              }>
              {({ ref, ...triggerHandler }) => (
                <Nav.Link
                  className={"my-1 my-lg-0"}
                  active={pathname === `/user/${userId}/settings`}
                  as={Link}
                  to={`/user/${userId}/settings`}
                  ref={ref}
                  {...triggerHandler}>
                  {isMobile && "Einstellungen"}{" "}
                  <FontAwesomeIcon icon={faCog} style={isMobile ? { marginLeft: 5 } : {}} />
                </Nav.Link>
              )}
            </OverlayTrigger>
            <OverlayTrigger
              transition={false}
              key={"premium"}
              placement={"bottom"}
              overlay={
                <Tooltip className={"webtie-tooltip"} id={"tooltip-logout"}>
                  Spielgruppe Plus
                </Tooltip>
              }>
              {({ ref, ...triggerHandler }) => (
                <Nav.Link
                  as={Link}
                  to={`/user/${userId}/plus`}
                  className={"my-1 my-lg-0"}
                  ref={ref}
                  {...triggerHandler}>
                  <span className="webtie-tooltip">
                    <>
                      {isMobile && "Spielgruppe Plus"}{" "}
                      <SpielgruppePlusIcon style={isMobile ? { marginLeft: 5 } : undefined} />
                    </>
                  </span>
                </Nav.Link>
              )}
            </OverlayTrigger>
            <OverlayTrigger
              transition={false}
              key={"logout"}
              placement={"bottom"}
              overlay={
                <Tooltip className={"webtie-tooltip"} id={"tooltip-logout"}>
                  Abmelden
                </Tooltip>
              }>
              {({ ref, ...triggerHandler }) => (
                <Nav.Link className={"pr-0 my-1 my-lg-0"} ref={ref} {...triggerHandler}>
                  <span
                    className="log-out-link"
                    onClick={() => {
                      signOut().then(() => history.push("/"));
                    }}>
                    {isMobile && "Abmelden"}{" "}
                    <FontAwesomeIcon icon={faPowerOff} style={isMobile ? { marginLeft: 5 } : {}} />
                  </span>
                </Nav.Link>
              )}
            </OverlayTrigger>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
