import { useEffect, useState } from "react";

import FirebaseService from "../actions/firebase/FirebaseActions";
import Registration from "../models/registration/Registration";
import { FirebaseSnapshotMapper } from "../actions/firebase/FirebaseSnapshotMapper";

export const useRegistrations = (userId: string): [Registration[], boolean, boolean?] => {
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [error, setError] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = FirebaseService.streamRegistrations(userId, {
      next: (querySnapshot) => {
        const updatedRegistrations = FirebaseSnapshotMapper.toRegistrations(querySnapshot);
        setRegistrations(updatedRegistrations);
        setLoading(false);
      },
      error: () => {
        setError(true);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [userId, setRegistrations, setLoading]);

  return [registrations, isLoading, error];
};
