import { JSONSchema7 } from "json-schema";

export const FormBuilderSchema: JSONSchema7 = {
  type: "object",
  title: "Formular bearbeiten",
  description: "Hier können Sie die Felder und Einstellungen des Anmeldeformulars bearbeiten.",
  properties: {
    title: {
      type: "string",
      title: "Titel"
    },
    releaseDate: {
      type: "object",
      title: "Geplante Veröffentlichung",
      description:
        "Falls die Anmeldung zu einem späteren Zeitpunkt veröffentlicht werden soll, können Sie folgend das Datum und die Uhrzeit angeben.",
      properties: {
        dateTime: {
          type: "string",
          title: "Datum und Zeit",
          format: "date-time"
        },
        timeZone: {
          type: "string",
          title: "Zeitzone",
          default: "Europe/Zurich",
          oneOf: [{ const: "Europe/Zurich", title: "Europe/Zurich" }]
        }
      }
    },
    formSettings: {
      type: "object",
      title: "Weitere Formular-Einstellungen",
      properties: {
        emailConfirmationField: {
          type: "string",
          title: "Feldname der E-Mail-Adresse für das Bestätigungs-E-mail",
          description:
            "Geben Sie die Namen des Formularfeldes der E-Mail-Adresse an, falls bei einer erfolgreichen Anmeldung eine E-Mail and die Person gesendet werden soll, welche die Anmeldung getätigt hat. (z.B. Emailadresse der Eltern). Falls noch kein Feld vorhanden ist, können Sie unter Formularfelder eines hinzufügen."
        },
        maxOffersToSelect: {
          type: "number",
          title: "Maximale Anzahl an auswählbaren Angeboten",
          description:
            "Geben Sie an, wie viele Angebote bei einer Anmeldung ausgewählt werden dürfen. Wenn Sie keine Limitierung möchten, lassen Sie das Feld leer oder geben Sie 0 ein.",
          minimum: 0,
          maximum: 99
        }
      }
    },
    formFields: {
      type: "array",
      description:
        "Folgend können Sie Formularfelder erstellen, bearbeiten und entfernen. " +
        "Über die '↑'- und '↓'-Buttons auf der rechten Seite können Sie die Position des entsprechenden Feldes anpassen. " +
        "Mit dem '-'-Button entfernen Sie das Feld. " +
        "Um ein neues Feld hinzuzufügen, klicken Sie auf den '+'-Button am Ende des Formulars. " +
        "Ebenfalls am Ende des Formulars befindet sich der 'Speichern'-Button. Beim Speichern aktualisiert sich jeweils die Vorschau.",
      title: "Formularfelder",
      items: {
        type: "object",
        required: ["title"],
        properties: {
          title: {
            type: "string",
            title: "Name"
          },
          fieldType: {
            type: "string",
            title: "Typ",
            oneOf: [
              { const: "string", title: "Text" },
              { const: "number", title: "Zahl" },
              { const: "integer", title: "Ganzzahl" },
              { const: "boolean", title: "Checkbox" },
              { const: "offer", title: "Angebot" },
              { const: "select", title: "Auswahl" },
              { const: "date", title: "Datum" },
              { const: "zipCode", title: "PLZ" },
              { const: "divider", title: "----------" },
              { const: "title", title: "Titel" },
              { const: "markdown", title: "Formatierter Text" }
            ]
          },
          fieldRequired: {
            type: "boolean",
            title: "Pflichtfeld"
          }
        },
        dependencies: {
          fieldType: {
            oneOf: [
              {
                properties: {
                  fieldType: {
                    enum: ["string", "number", "integer", "boolean", "offer"]
                  }
                }
              },
              {
                properties: {
                  fieldType: {
                    enum: ["title"]
                  },
                  size: {
                    title: "Titel grösse",
                    type: "string",
                    default: "h2",
                    oneOf: [
                      { const: "h1", title: "h1 (gross)" },
                      { const: "h2", title: "h2" },
                      { const: "h3", title: "h3" },
                      { const: "h4", title: "h4" },
                      { const: "h5", title: "h5" },
                      { const: "h6", title: "h6 (klein)" }
                    ]
                  },
                  description: {
                    title: "Titel text",
                    type: "string"
                  }
                }
              },
              {
                properties: {
                  fieldType: {
                    enum: ["markdown"]
                  },
                  markdownDescription: {
                    title: "Formatierter Text",
                    type: "string"
                  }
                }
              },
              {
                properties: {
                  fieldType: {
                    enum: ["select"]
                  },
                  values: {
                    title: "Auswahlmöglichkeiten",
                    type: "array",
                    items: {
                      type: "string"
                    }
                  }
                }
              },
              {
                properties: {
                  fieldType: {
                    enum: ["date"]
                  },
                  minDate: {
                    title: "Datum Untergrenze",
                    type: "string",
                    format: "date"
                  },
                  maxDate: {
                    title: "Datum Obergrenze",
                    type: "string",
                    format: "date"
                  },
                  description: {
                    title: "Hinweis Text",
                    type: "string"
                  }
                }
              },
              {
                properties: {
                  fieldType: {
                    enum: ["zipCode"]
                  },
                  zipCodes: {
                    title: "Erlaubte Postleitzahlen (Kommagetrennt)",
                    description: "Z.B.: 5200, 5210, 5116",
                    type: "string"
                  }
                }
              }
            ]
          }
        }
      }
    }
  }
};
