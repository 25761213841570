import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

import { firebaseAppAuth } from "../lib/firebase";

export const useAuthUser = (): firebase.User | null => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    if (firebaseAppAuth) {
      firebaseAppAuth.onAuthStateChanged((authUser) => {
        if (authUser) {
          setCurrentUser(authUser);
        } else {
          setCurrentUser(null);
        }
      });
    }
  }, []);

  return currentUser;
};
