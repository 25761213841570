import Form, { UiSchema } from "@rjsf/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { JSONSchema7 } from "json-schema";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import { Alert, Spinner } from "react-bootstrap";

import { PrimaryButton } from "../common/button/Buttons";
import { ContentLayout } from "../common/Layout";
import { getErrorByCode } from "../../helper/firebase/getErrorByCode";
import { useAuthResetPassword } from "../../hooks/useAuthResetPassword";

const schema: JSONSchema7 = {
  type: "object",
  required: ["email"],
  properties: {
    email: {
      type: "string",
      title: "E-Mail"
    }
  }
};

const uiSchema: UiSchema = {
  email: {
    "ui:widget": "email"
  }
};

interface Props {
  user: firebase.User | null | undefined;
}

const ResetPassword: FunctionComponent<Props> = ({ user }: Props) => {
  const history = useHistory();
  const { resetPassword, isLoading } = useAuthResetPassword();
  const [resetSubmitted, setResetSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user]);

  const submitResetPassword = async (formData: any): Promise<void> => {
    const { email } = formData;

    resetPassword(email)
      .then(() => setResetSubmitted(true))
      .catch((e) => {
        toast.error(`Zurücksetzen des Passworts ist fehlgeschlagen. ${getErrorByCode(e.code)}`);
      });
  };

  return (
    <ContentLayout title={"Passwort zurücksetzen"} siteTitle={"Spielgruppe Online - Passwort zurücksetzen"}>
      <Form schema={schema} uiSchema={uiSchema} onSubmit={(e) => submitResetPassword(e.formData)} showErrorList={false}>
        <>
          <p>
            Wenn Sie das Passwort zurücksetzen, erhalten Sie eine E-Mail mit den Anweisungen zum Zurücksetzen des
            Passworts.
          </p>
          <PrimaryButton active={!isLoading || !resetSubmitted} type="submit">
            Passwort zurücksetzen {isLoading && <Spinner size={"sm"} animation={"border"} />}
          </PrimaryButton>
        </>
      </Form>
      {resetSubmitted && (
        <Alert variant={"success"} className={"mt-3"}>
          Bitte prüfen Sie Ihr E-Mail Postfach und folgen Sie den Anweisungen des erhaltenen E-Mails.
        </Alert>
      )}
      <p className={"mt-3"}>
        Wissen Sie Ihr Passwort noch? <Link to="/signin">Hier gehts zum Login</Link>
      </p>
    </ContentLayout>
  );
};

export default ResetPassword;
