const baseEndpoint = process.env.REACT_APP_PUBLIC_BASE_ENDPOINT;

export class RegistrationFormEndpoints {
  public readonly registrationEndpoint: string;
  public readonly limitsEndpoint: string;
  public readonly schemaEndpoint: string;

  constructor(private readonly userId: string) {
    if (!baseEndpoint) {
      throw new Error("Der Anmeldeserver konnte nicht ermittelt werden. Bitte wenden Sie sich an den Support.");
    }

    this.registrationEndpoint = `${baseEndpoint}/api/v1/registration/user/${userId}`;
    this.limitsEndpoint = `${baseEndpoint}/api/v1/limits/user/${userId}`;
    this.schemaEndpoint = `${baseEndpoint}/api/v1/schema/user/${userId}`;
  }
}
