import Form, { UiSchema } from "@rjsf/core";
import React, { FunctionComponent, useEffect } from "react";
import { JSONSchema7 } from "json-schema";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";

import { PrimaryButton } from "../common/button/Buttons";
import { useAuthRegistration } from "../../hooks/useAuthRegistration";
import { getErrorByCode } from "../../helper/firebase/getErrorByCode";
import { ContentLayout } from "../common/Layout";

const schema: JSONSchema7 = {
  type: "object",
  required: ["email", "password", "passwordConfirmation"],
  properties: {
    email: {
      type: "string",
      title: "Email"
    },
    password: {
      type: "string",
      title: "Passwort",
      minLength: 6
    },
    passwordConfirmation: {
      type: "string",
      title: "Passwort",
      minLength: 6
    }
  }
};

const uiSchema: UiSchema = {
  email: {
    "ui:widget": "email"
  },
  password: {
    "ui:widget": "password"
  },
  passwordConfirmation: {
    "ui:widget": "password"
  }
};

function transformErrors(errors: any): any {
  return errors.map((error: any): any => {
    if (error.name === "minLength") {
      error.message = "Das Passwort sollte nicht kürzer als 6 Zeichen sein.";
    }
    return error;
  });
}

interface Props {
  user: firebase.User | null | undefined;
}

const SignUp: FunctionComponent<Props> = ({ user }: Props) => {
  const history = useHistory();
  const { createUserWithEmailAndPassword, isLoading } = useAuthRegistration();

  useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user]);

  const submitSignUp = async (formData: any): Promise<void> => {
    const { email, password, passwordConfirmation } = formData;

    if (!password === passwordConfirmation) {
      toast.error("Passwörter stimmen nicht überein.");
    }

    createUserWithEmailAndPassword(email, password).catch((e) => {
      toast.error(`Die Registrierung ist fehlgeschlagen. ${getErrorByCode(e.code)}`);
    });
  };

  return (
    <ContentLayout title={"Registrieren"} siteTitle={"Spielgruppe Online - Registrieren"}>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={(e) => submitSignUp(e.formData)}
        showErrorList={false}
        transformErrors={transformErrors}>
        <>
          <p>
            Mit der Registrierung akzeptieren Sie die{" "}
            <a target={"_blank"} href="/agb">
              AGBs
            </a>
          </p>
          <PrimaryButton active={!isLoading} type="submit">
            Registrieren
          </PrimaryButton>
        </>
      </Form>
      <p className={"mt-3"}>
        Haben Sie bereits einen Account? <Link to="/signin">Hier gehts zum Login</Link>
      </p>
    </ContentLayout>
  );
};

export default SignUp;
