import { RouteComponentProps } from "react-router";
import { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { RegistrationForm } from "@webtie-ch/form-widget-library";
import { Helmet } from "react-helmet";

import { RegistrationFormEndpoints } from "./RegistrationFormEndpoints";

interface MatchParams {
  userId: string;
}

type Props = RouteComponentProps<MatchParams>;

export const PublicRegistrationForm: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;

  const registrationEndpoints = new RegistrationFormEndpoints(userId);

  return (
    <div>
      <noscript>
        Sie müssen Javascript zulassen, um das Anmeldeformular zu verwenden. Bitte nehmen Sie diese Änderungen in den
        Sicherheitseinstellungen Ihres Webbrowsers vor. Ansonsten verwenden Sie einen modernen Webbrowser wie Google
        Chrome oder Mozilla Firefox.
      </noscript>
      <Container className={"py-5"}>
        <Helmet>
          <title>Spielgruppen Anmeldung</title>
          <meta name="description" content="Melden Sie Ihr Kind online für die Spielgruppe an." />
        </Helmet>
        <RegistrationForm
          limitsEndpoint={registrationEndpoints.limitsEndpoint}
          registrationEndpoint={registrationEndpoints.registrationEndpoint}
          schemaEndpoint={registrationEndpoints.schemaEndpoint}
        />
      </Container>
    </div>
  );
};
