import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";

import EmailSettings from "./EmailSettings";
import { useUserData } from "../../hooks/useUserData";
import { useAuthentication } from "../../hooks/useAuthentication";
import { ContentLayout } from "../common/Layout";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

export const Email: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  const [userData, isLoading, isError] = useUserData(userId);
  useAuthentication(props.isAuthenticated);

  if (isError) return <p>Fehler beim Laden der Daten</p>;
  return (
    <ContentLayout
      siteTitle={"Spielgruppe Online - E-Mail Einstellungen"}
      title={"E-Mail Einstellungen"}
      isLoading={isLoading}>
      <EmailSettings userId={userId} emailSettings={userData?.settings?.mailSettings} />
    </ContentLayout>
  );
};
