import React, { FunctionComponent, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Form } from "@rjsf/bootstrap-4";
import { Fields, UnlimitedWidgets } from "@webtie-ch/form-widget-library";
import { JSONSchema7 } from "json-schema";
import { toast } from "react-toastify";

import { FormSettings as FormSettingsModel } from "../../models/userData/settings/FormSettings";
import { PrimaryButton } from "../common/button/Buttons";
import { FormBuilderUiSchema } from "./FormBuilderUiSchema";
import { FormBuilderSchema } from "./FormBuilderSchema";
import { FormDataToWebtieJsonSchemaMapper } from "./FormDataToWebtieJsonSchemaMapper";
import { WebtieJsonSchemaToFormDataMapper } from "./WebtieJsonSchemaToFormDataMapper";
import { RawFormSchema, RawFormSettings } from "../../models/userData/settings/RawFormSettings";
import FirebaseService from "../../actions/firebase/FirebaseActions";
import FormSchemaService from "../../actions/schema/FormSchemaActions";
import useSubscriptionType from "../../hooks/useSubscriptionType";
import { SpielgruppePlusIcon } from "../common/SpielgruppePlusIcon";
import { getFormLink } from "../../helper/getFormLink";
import { trackEvent } from "../../helper/analytics";

interface Props {
  userId: string;
  formSettings?: FormSettingsModel;
}

export const FormBuilder: FunctionComponent<Props> = ({ formSettings, userId }: Props) => {
  if (!formSettings) {
    return <p>Formular-Einstellungen konnten nicht geladen werden. Bitte wenden Sie sich an den Support.</p>;
  }
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const subscriptionType = useSubscriptionType(userId);

  const formSchema = formSettings.formSchema;
  const jsonSchema = formSchema?.formSchema;
  const uiSchema = formSchema?.uiSchema;

  if (!formSchema || !jsonSchema || !uiSchema) {
    return <p>Fehler beim Laden des Schemas</p>;
  }

  const mapper = new WebtieJsonSchemaToFormDataMapper(formSchema);
  const formData = mapper.mapToFormData();

  const updateFormSettings = (formData: any): void => {
    const mapper = new FormDataToWebtieJsonSchemaMapper(formData);
    const jsonSchema = mapper.mapToJsonSchema();
    const uiSchema = mapper.mapToUiSchema();
    const emailConfirmationField = formData?.formSettings?.emailConfirmationField;
    const maxOffersToSelect = formData?.formSettings?.maxOffersToSelect;

    const rawFormSchema: RawFormSchema = {
      formSchema: JSON.stringify(jsonSchema),
      uiSchema: JSON.stringify(uiSchema),
      emailConfirmationField: emailConfirmationField,
      options: {
        maxOffersToSelect: maxOffersToSelect
      }
    };

    const rawFormSettings: RawFormSettings = {
      formSchemaEndpoint: formSettings.formSchemaEndpoint,
      formSchema: rawFormSchema
    };

    try {
      FirebaseService.updateFormSettings(userId, rawFormSettings)
        .then(() => {
          toast.success("Die Einstellungen wurden erfolgreich gespeichert.");
          const limitFields = FormSchemaService.getLimitProperties(uiSchema);
          FirebaseService.addLimitIfNotExist(userId, limitFields).catch(() =>
            toast.error(
              "Die Angebote konnten nicht automatisch erstellt werden. Bitte erstellen Sie diese manuell unter ANGEBOTE."
            )
          );
        })
        .catch((error) => {
          toast.error(`Fehler: ${error}`);
        });
    } catch (e) {
      toast.error(
        `Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support: Fehler: ${e}`
      );
    } finally {
      trackEvent("update-form");
    }
  };

  const previewButtonText = showPreview ? "Vorschau ausblenden" : "Vorschau einblenden";

  return (
    <div>
      <Container style={{ display: "initial" }}>
        <div className="alert-info rounded p-3 mb-5">
          Bearbeiten Sie keine Feldnamen, welche bereits Daten von Anmeldungen enthalten. Daten bestehender Anmeldungen
          können dabei verloren gehen.
          <br />
          Einige Feldtypen sind nur mit Spielgruppe Plus <SpielgruppePlusIcon /> verfügbar.
        </div>
        <div className="d-flex py-2 justify-content-end">
          <PrimaryButton className={"mr-2"} onClick={() => navigator.clipboard.writeText(getFormLink(userId))}>
            Formular Link kopieren
          </PrimaryButton>
          <a target={"_blank"} href={getFormLink(userId)}>
            <PrimaryButton className={"mr-2"}> Zum Formular </PrimaryButton>
          </a>
          <PrimaryButton onClick={() => setShowPreview(!showPreview)}>{previewButtonText}</PrimaryButton>
        </div>
        <Row>
          <Col>
            <Form
              uiSchema={FormBuilderUiSchema(subscriptionType)}
              schema={FormBuilderSchema}
              formData={formData}
              onSubmit={(e: any) => updateFormSettings(e.formData)}>
              <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
            </Form>
          </Col>
          {showPreview && (
            <Col>
              <h2 className={"pb-4"}>Vorschau</h2>
              {jsonSchema && uiSchema && (
                <Form
                  uiSchema={uiSchema}
                  schema={jsonSchema as JSONSchema7}
                  fields={Fields}
                  widgets={UnlimitedWidgets}
                  onSubmit={() => toast.success("Neue Anmeldung wäre eingetroffen")}>
                  <PrimaryButton type={"submit"}>Absenden</PrimaryButton>
                </Form>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};
