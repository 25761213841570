import React, { FunctionComponent } from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ButtonRow: FunctionComponent<Props> = ({ children, className }: Props) => {
  return <div className={`my-4 ${className}`}>{children}</div>;
};

export default ButtonRow;
