import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import firebaseConfig from "./firebaseConfig";

const firebaseApp = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
const firebaseAppAuth = firebaseApp.auth();

firebaseApp.firestore().settings({ ignoreUndefinedProperties: true });

const db = firebaseApp.firestore();

if (process.env.REACT_APP_LOCAL === "TRUE") {
  console.log("Use firebase emulator");
  db.useEmulator("localhost", 5003);
  firebaseAppAuth.useEmulator("http://localhost:5004/");
}

export { firebaseAppAuth, firebaseApp, db };
