import styled from "styled-components";
import { Button } from "react-bootstrap";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

export const PrimaryButton = styled(Button)`
  background-color: #1991ac;
  border-color: #1991ac;
  color: white;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    border-color: #1a9cb5;
    background-color: #1a9cb5;
    text-decoration: none;
    opacity: 0.75;
  }
`;

export const DeleteButton = styled((props) => <Button variant={"danger"} {...props} />)`
  display: inline-block;
  background-color: #eb6244;
  border-color: #eb6244;
  color: white;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    border-color: #eb6244;
    background-color: #eb6244;
    text-decoration: none;
    opacity: 0.75;
  }
`;

const StyledBackButtonButton = styled(Button)`
  color: #1991ac;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: rgba(25, 145, 172, 0.75);
  }
`;

export const BackButton: FunctionComponent<{
  to: string;
  className?: string;
}> = ({ to, className }: { to: string; className?: string }) => {
  return (
    <Link to={to}>
      <StyledBackButtonButton className={className} variant={"link"}>
        &lt; Zurück
      </StyledBackButtonButton>
    </Link>
  );
};
