import { useState } from "react";

import { firebaseAppAuth } from "../lib/firebase";

interface ReturnType {
  createUserWithEmailAndPassword: (user: string, password: string) => Promise<void>;
  isLoading: boolean;
}

export const useAuthRegistration = (): ReturnType => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const createUserWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
    setLoading(true);
    try {
      await firebaseAppAuth.createUserWithEmailAndPassword(email, password);
      await firebaseAppAuth.currentUser?.sendEmailVerification();
    } finally {
      setLoading(false);
    }
  };

  return {
    createUserWithEmailAndPassword: createUserWithEmailAndPassword,
    isLoading: isLoading
  };
};
