import Form, { UiSchema } from "@rjsf/core";
import React, { FunctionComponent, useEffect } from "react";
import { JSONSchema7 } from "json-schema";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";

import { firebaseAppAuth } from "../../lib/firebase";
import { PrimaryButton } from "../common/button/Buttons";
import { ContentLayout } from "../common/Layout";

const schema: JSONSchema7 = {
  type: "object",
  required: ["email", "password"],
  properties: {
    email: {
      type: "string",
      title: "Email"
    },
    password: {
      type: "string",
      title: "Passwort"
    }
  }
};

const uiSchema: UiSchema = {
  email: {
    "ui:widget": "email"
  },
  password: {
    "ui:widget": "password"
  }
};

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  user: firebase.User | null | undefined;
}

const SignIn: FunctionComponent<Props> = ({ user }: Props) => {
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    if (user) {
      const nextPage = query.get("nextPage");
      if (nextPage) {
        history.push(nextPage);
      } else {
        history.push(`/user/${user.uid}/dashboard`);
      }
    }
  }, [user]);

  const submitSignIn = async (formData: any): Promise<void> => {
    const { email, password } = formData;

    try {
      if (firebaseAppAuth) {
        await firebaseAppAuth.signInWithEmailAndPassword(email, password);
      }
    } catch (error) {
      toast.error("Anmeldedaten sind nicht korrekt. Bitte versuchen Sie es erneut.");
    }
  };

  return (
    <ContentLayout title={"Anmelden"} siteTitle={"Spielgruppe Online - Anmelden"}>
      <Form schema={schema} uiSchema={uiSchema} onSubmit={(e) => submitSignIn(e.formData)}>
        <PrimaryButton type="submit">Anmelden</PrimaryButton>
      </Form>
      <p className={"mt-3"}>
        Haben Sie noch keinen Account? <Link to="/signup">Hier gehts zur Registrierung</Link>
      </p>
      <p className={"mt-3"}>
        Passwort vergessen? <Link to="/reset-password">Hier setzen Sie es zurück</Link>
      </p>
    </ContentLayout>
  );
};

export default SignIn;
