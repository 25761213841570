import { useEffect, useState } from "react";

import FormSchema from "../models/schema/FormSchema";
import FormSchemaService from "../actions/schema/FormSchemaActions";

export const useFormSchema = (userId: string): [formSchema: FormSchema | undefined, fetchFormSchema: () => void] => {
  const [formSchema, setFormSchema] = useState<FormSchema>();

  function fetchFormSchema(): void {
    FormSchemaService.getFormSchemaByUserId(userId).then((schema) => setFormSchema(schema));
  }

  useEffect(() => {
    fetchFormSchema();
  }, [userId]);

  return [formSchema, fetchFormSchema];
};
