import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

export const Agb: FunctionComponent = () => {
  return (
    <Container>
      <Helmet>
        <title>Spielgruppe Online - AGB</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1 className={"mt-5"}>Allgemeine Geschäftsbedingungen für Spielgruppe Online</h1>

      <h2>1 Gegenstand und Umfang des Auftrags</h2>

      <p>
        1.1 Gegenstand dieser Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") ist die Nutzung der
        Software-as-a-Service-Anwendung Spielgruppe Online (nachfolgend "Spielgruppe Online"). Webtie.ch (nachfolgend
        "Webtie") stellt dem Kunden (nachfolgend "Kunde" oder "Sie") für die Dauer dieses Vertrages Webtie in der
        jeweils aktuellen Version über das Internet zur Nutzung zur Verfügung.
      </p>

      <p>
        1.2 Webtie kann von Spielgruppen oder vergleichbaren Gruppen genutzt werden, um die Anmeldung und Verwaltung von
        Kindern zu erleichtern. Der Zugang des Kunden zu Spielgruppe Online und dessen Nutzung und/oder die einzelnen
        Funktionen können von Webtie an bestimmte Bedingungen geknüpft werden (z.B. Überprüfung der bei der Anmeldung
        angegebenen Kontaktdaten des Kunden).
      </p>

      <p>
        1.3 Soweit nicht ausdrücklich in Textform vereinbart, gelten abweichende, ergänzende oder entgegenstehende
        Bedingungen des Kunden nicht.
      </p>

      <h2>2. Inhalt und Umfang der Dienstleistung</h2>

      <p>
        2.1 Webtie erbringt die vertraglichen Leistungen, insbesondere den Zugang zu Spielgruppe Online. Der Kunde
        erhält keine Rechte an Spielgruppe Online einschließlich der dazugehörigen Unterlagen.
      </p>

      <p>
        2.2 Soweit Webtie Leistungen unentgeltlich erbringt, hat der Kunde keinen Anspruch auf deren weitere Erbringung.
        Webtie ist jederzeit berechtigt, solche bisher unentgeltlich erbrachten Leistungen einzustellen, zu ändern oder
        nur noch gegen Entgelt anzubieten sowie neue Leistungen/Funktionalitäten unentgeltlich oder gegen Entgelt
        anzubieten, und zwar mit einer Ankündigungsfrist von sechs (3) Monaten nach Mitteilung an den Kunden in Textform
        (z.B. per E-Mail).
      </p>

      <p>
        2.3 Fester Leistungsbestandteil jedes registrierungspflichtigen Spielgruppe Online-Kontos ist die regelmäßige
        Zusendung von E-Mail-Benachrichtigungen ("Info-E-Mails"). Die Info-E-Mails enthalten insbesondere Nachrichten
        über Updates, Angebote und sonstige Spielgruppe Online-bezogene Informationen. Sie können die Info-E-Mails
        jederzeit wieder abbestellen, indem Sie den "Abbestellen"-Button in den Info-E-Mails anklicken oder das
        Kontaktformular auf unserer Website nutzen.
      </p>

      <p>
        2.4 Webtie entwickelt Spielgruppe Online ständig weiter, um den Service zu verbessern. Spielgruppe Online kann
        nur in der jeweils aktuellen Version genutzt werden. Der Kunde hat keinen Anspruch auf die Nutzung einer
        bestimmten Version von Spielgruppe Online.
      </p>

      <p>
        2.5 Webtie setzt für die Erbringung der Dienstleistung Unterauftragnehmer oder Unterauftragnehmer ein. Der Kunde
        stimmt dem Einsatz von Unterauftragnehmern bei Vertragsabschluss zu.
      </p>

      <p>
        2.6 Spielgruppe Online kann Links zu Diensten von Drittanbietern auf externen Websites enthalten, die über
        Spielgruppe Online erreichbar sind und den Nutzungsbedingungen dieser Drittanbieter unterliegen. Webtie verlinkt
        nur auf solche Websites, für deren Inhalt ausschließlich der jeweilige Drittanbieter verantwortlich ist.
      </p>

      <p>
        2.7 Soweit Kunden auch über mobile Anwendungen (mobile Apps) auf Spielgruppe Online zugreifen können, werden
        diese über Plattformen Dritter, wie z.B. dem Google Play Store oder dem Apple App Store, zur Verfügung gestellt.
        Die Nutzung der Plattformen bzw. mobilen Anwendungen an sich unterliegt den jeweiligen Bedingungen, die beim
        Download bzw. beim Zugriff auf die Plattform bzw. mobile Anwendung vereinbart werden, und zwar nur ergänzend zu
        diesen AGB.
      </p>

      <p>
        2.8 Die Webtie ist nicht verpflichtet, Leistungen zu erbringen, wenn und solange besondere Umstände vorliegen,
        die die Webtie nicht zu vertreten hat.
      </p>

      <p>
        a) Zu diesen besonderen Umständen zählen Ereignisse höherer Gewalt und sonstige unvorhersehbare Ereignisse, die
        außerhalb des Einflussbereiches beider Vertragsparteien liegen (z.B. Betriebsstörungen,
        Materialbeschaffungsschwierigkeiten, Transportverzögerungen, Streiks, Aussperrungen, Mangel an Arbeitskräften,
        Energie oder Rohstoffen, Schwierigkeiten bei der Beschaffung notwendiger behördlicher Genehmigungen) und
      </p>

      <p>
        b) Umstände, die ausschließlich in der Sphäre des Kunden liegen, wie z.B. das Ausbleiben oder der Verzug des
        Kunden bei der Erbringung von Mitwirkungsleistungen, sowie Zeiten, in denen Webtie auf notwendige Informationen,
        Unterlagen oder Entscheidungen des Kunden wartet. In diesem Fall unter b) bleibt die Zahlungsverpflichtung des
        Kunden bestehen.
      </p>

      <h2>3. Online-Vertragsabschluss</h2>

      <p>
        3.1 Die AGB gelten auch für solche Verträge, die online über die Website (
        <a data-auto-recognition="true" href="https://www.spielgruppe.online" target="_blank">
          https://www.spielgruppe.online
        </a>
        ,{" "}
        <a data-auto-recognition="true" href="https://spielgruppe.online" target="_blank">
          https://spielgruppe.online
        </a>
        ,{" "}
        <a data-auto-recognition="true" href="https://portal.spielgruppe.online" target="_blank">
          https://portal.spielgruppe.online
        </a>
        ) oder ein sonstiges Online-Verfahren von Webtie abgeschlossen werden. Die Online-Präsentation von Spielgruppe
        Online stellt noch kein rechtlich bindendes Angebot dar, sondern lediglich eine unverbindliche Aufforderung an
        den Kunden, seinerseits ein Angebot abzugeben. Durch den Abschluss der Bestellung des Kunden durch Anklicken
        eines Bestellbuttons (z.B. "Kaufen", "kostenpflichtig anmelden", "kostenlos testen") gibt der Kunde ein
        verbindliches Angebot zum Abschluss eines Vertrages über die ausgewählten Leistungen ab. Der Antrag kann jedoch
        nur abgegeben und übermittelt werden, wenn der Kunde diese Vertragsbedingungen durch Anklicken der
        entsprechenden Checkbox akzeptiert und damit in seinen Antrag aufgenommen hat.
      </p>

      <p>
        3.2 Die Webtie bestätigt den Eingang der elektronischen Bestellung des Kunden durch Versendung einer
        automatischen E-Mail. Mit dieser Auftragsbestätigung oder spätestens mit der Erbringung der Leistung oder der
        Freischaltung von Spielgruppe Online kommt der Vertrag zwischen Webtie und dem Kunden zustande.
      </p>

      <p>
        3.3 Nach einer elektronischen Online-Bestellung des Kunden werden die individuellen Bestelldaten des Kunden von
        Webtie gespeichert. Jeder Kunde erhält seine Bestelldaten in der automatischen Empfangsbestätigung nochmals
        zugesandt. Die aktuelle Fassung der AGB kann jederzeit in den Online-Einstellungen der Spielgruppe abgerufen
        werden.
      </p>

      <h2>4. Nutzungsrechte</h2>

      <p>
        4.1 Der Kunde erhält im Rahmen seiner Bestellung und der nachfolgenden Bestimmungen das nicht ausschließliche
        und nicht übertragbare, auf die Dauer des Vertrages beschränkte Recht, die bestellten Funktionen von Spielgruppe
        Online im eigenen Verein zu nutzen. Spielgruppe Online läuft ausschließlich auf den Servern von Webtie oder
        deren Unterauftragnehmern. Soweit dem Kunden nicht ausdrücklich Rechte eingeräumt werden, stehen alle Rechte an
        Spielgruppe Online und an etwaigen Arbeitsergebnissen ausschließlich der Webtie zu, auch wenn diese durch
        Vorgaben oder Mitarbeit des Kunden entstanden sind.
      </p>

      <p>
        4.2 Die Vermietung, Überlassung oder Nutzung durch oder für Dritte, Timesharing, Outsourcing oder
        Rechenzentrumsnutzung oder eine sonstige entgeltliche oder unentgeltliche Nutzung von Spielgruppe Online durch
        oder für Dritte ist ohne vorherige schriftliche Zustimmung von Webtie nicht gestattet. Die abgerufenen Inhalte
        dürfen nur für den eigenen Gebrauch des Kunden verwendet werden.
      </p>

      <p>
        4.3 Der Kunde darf Spielgruppe Online weder ganz noch teilweise kopieren, übersetzen, disassemblieren,
        dekompilieren, zurückentwickeln oder anderweitig modifizieren, bearbeiten oder davon abgeleitete Werke
        erstellen, es sei denn, der Verwendungszweck von Spielgruppe Online gemäß der aktuellen Leistungsbeschreibung
        oder zwingende gesetzliche Vorschriften lassen dies zu.
      </p>

      <p>
        4.4 Bei Hinweisen auf eine rechtswidrige Nutzung der Inhalte ist Webtie berechtigt, die Nutzung zu prüfen, den
        Zugang zu sperren und bei Vorliegen eines wichtigen Grundes das Vertragsverhältnis fristlos zu kündigen. Es wird
        auf Ziffer 9 dieser AGB verwiesen.
      </p>

      <p>
        4.5 Bei Beendigung der Vereinbarung (a) endet die Möglichkeit des Kunden, auf Spielgruppe Online zuzugreifen,
        (b) endet das Recht des Kunden, Spielgruppe Online zu nutzen, und (c) werden alle zum Zeitpunkt der Beendigung
        oder Kündigung geschuldeten Beträge sofort fällig und zahlbar.
      </p>

      <p>
        4.6 Marken, Firmenlogos, sonstige Kennzeichen oder Schutzvermerke, Urheberrechtsvermerke, Seriennummern und
        sonstige Erkennungsmerkmale von Spielgruppe Online oder Webtie dürfen weder in elektronischer Form noch in
        Ausdrucken entfernt oder verändert werden.
      </p>

      <p>
        4.7 Vertragsgegenstände, Unterlagen, Vorschläge usw. der Webtie, die dem Kunden vor oder nach Vertragsschluss
        zugänglich werden, gelten als geistiges Eigentum und als Geschäfts- und Betriebsgeheimnis der Webtie. Sie dürfen
        ohne schriftliche Zustimmung von Webtie in keiner Weise verwendet werden und sind geheim zu halten.
      </p>

      <h2>5. Verfügbarkeit des Dienstes</h2>

      <p>
        5.1 Der Kunde erkennt an, dass eine 100%ige Verfügbarkeit von Spielgruppe Online aufgrund unvorhersehbarer sowie
        vorhersehbarer Umstände technisch unmöglich ist. Webtie übernimmt daher keine Gewähr für die ununterbrochene
        Nutzbarkeit oder die ständige Erreichbarkeit des Dienstes, bemüht sich aber, Spielgruppe Online möglichst
        konstant verfügbar zu halten. Insbesondere geplante Wartungs-, Sicherheits- oder Kapazitätsprobleme sowie
        Ereignisse, die nicht im Einflussbereich der Webtie liegen (z.B. Störungen öffentlicher Kommunikationsnetze,
        Stromausfälle etc.), können jedoch zu kurzfristigen Einschränkungen oder zur vorübergehenden Einstellung von
        Spielgruppe Online führen.
      </p>

      <p>
        5.2 Webtie wird nach Möglichkeit geplante Systemunterbrechungen zu verkehrsarmen Zeiten, z.B.
        Wartungsintervallen, durchführen, in denen der Dienst vorübergehend nicht verfügbar ist. Die Webtie kann den
        Betrieb der Leistung unterbrechen, wenn dies aus wichtigen Gründen erforderlich ist (z.B. bei Störungen oder
        Missbrauchsgefahr). Schadensersatzansprüche seitens der Webtie können hieraus nicht hergeleitet werden.
      </p>

      <p>
        5.3 Die Haftung für Betriebsunterbrechungen, die über die garantierte Verfügbarkeit hinausgehen und nicht Leben,
        Körper oder Gesundheit betreffen, ist auf Vorsatz und grobe Fahrlässigkeit beschränkt.
      </p>

      <h2>6. Voraussetzung für die Nutzung beim Kunden</h2>

      <p>
        6.1 Der Kunde verfügt über die für den Zugang zu Spielgruppe Online erforderliche IT-Infrastruktur,
        Systemumgebung und Telekommunikationseinrichtungen. Der Kunde ist für seine Hard- und Softwarekomponenten sowie
        für seinen Zugang zum Internet selbst verantwortlich. Nachteile und Mehrkosten, die sich aus der Verletzung von
        Mitwirkungspflichten ergeben, hat der Kunde zu tragen. Um Spielgruppe Online nutzen zu können, kann es von Zeit
        zu Zeit erforderlich sein, dass der Kunde eine aktuelle Browserversion installiert.
      </p>

      <p>
        6.2 Die dem Kunden selbst entstehenden Kosten, insbesondere durch die Datenübermittlung an den jeweiligen
        Netzbetreiber, sind sowohl bei entgeltlichen als auch bei unentgeltlichen Leistungen vom Kunden selbst zu
        tragen.
      </p>

      <p>
        6.3 Spielgruppe Online ist möglicherweise in anderen Ländern als der Schweiz nicht verfügbar. Webtie weist zudem
        darauf hin, dass bei der Nutzung von Spielgruppe Online aus dem Ausland zusätzliche Kosten anfallen können, die
        der Kunde zu tragen hat und selbst mit seinem lokalen Anbieter abklären sollte. Es ist möglich, dass das
        Herunterladen oder der Zugriff auf Spielgruppe Online in einem ausländischen Roaming-Netz eingeschränkt oder
        beeinträchtigt ist.
      </p>

      <h2>7. Verpflichtungen des Kunden</h2>

      <p>
        7.1 Der Kunde hat als wesentliche Vertragspflicht die vereinbarten Mitwirkungsleistungen unentgeltlich und im
        erforderlichen Umfang rechtzeitig, ordnungsgemäß und vollständig zu erbringen. Insbesondere wird der Kunde
        Webtie alle für die Vertragsdurchführung erforderlichen Informationen rechtzeitig zur Verfügung stellen.
      </p>

      <p>
        7.2 Der Kunde ist dafür verantwortlich, dass bei der Nutzung des Dienstes durch ihn oder seine
        Erfüllungsgehilfen die geltenden gesetzlichen Bestimmungen, behördlichen Auflagen, diese AGB, etwaige
        Nutzungsregelungen Dritter und ergänzende Hinweise von Webtie eingehalten werden. Der Kunde ist für alle Inhalte
        der an die Webtie übermittelten Daten verantwortlich. Die Webtie geht davon aus, dass die übermittelten Daten
        und Informationen weder rechts- noch sittenwidrig sind und keine Rechte Dritter verletzen. Der Kunde versichert,
        dass er berechtigt ist, die von ihm zur Verfügung gestellten Informationen, Inhalte (insbesondere Fotos und
        Videos), Daten und Logos zu speichern, zu nutzen und zugänglich zu machen und dass er insbesondere datenschutz-,
        urheber- und markenrechtliche Bestimmungen beachten wird.
      </p>

      <p>
        7.3 Der Kunde ist verpflichtet, den unbefugten Zugriff Dritter auf die geschützten Bereiche von Spielgruppe
        Online durch geeignete Vorkehrungen zu verhindern und Benutzernamen und Passwörter geheim zu halten. Es wird
        vereinbart, dass jede Anmeldung mit dem entsprechenden Benutzernamen und Passwort als durch den Kunden oder die
        von ihm autorisierten Nutzer erfolgt gilt. Der Kunde ist für Handlungen und Unterlassungen seiner autorisierten
        Nutzer wie für seine eigenen Handlungen und Unterlassungen verantwortlich und verpflichtet diese zur
        vertragsgemäßen Nutzung von Spielgruppe Online.
      </p>

      <p>
        7.4 Der Kunde stellt Webtie von sämtlichen Ansprüchen Dritter frei, die auf einer rechtswidrigen Nutzung von
        Spielgruppe Online durch den Kunden beruhen oder aus datenschutzrechtlichen, urheberrechtlichen oder sonstigen
        Rechtsstreitigkeiten resultieren, die der Kunde im Zusammenhang mit der Nutzung von Spielgruppe Online
        verursacht. Im Falle von Ansprüchen aus Verschulden gilt dies nicht, wenn der Kunde weder vorsätzlich noch
        fahrlässig gehandelt hat.
      </p>

      <p>
        7.5 Der Kunde verpflichtet sich, die Kontaktdaten (u.a. Adresse, E-Mail, gesetzliche Vertreter) stets auf dem
        neuesten Stand zu halten und Webtie unverzüglich über alle relevanten Änderungen zu informieren.
      </p>

      <p>
        7.6 Der Kunde ist verpflichtet, seine Daten und Informationen vor der Eingabe auf Viren oder andere schädliche
        Komponenten zu prüfen und zu diesem Zweck dem Stand der Technik entsprechende Virenschutzprogramme einzusetzen.
      </p>

      <p>
        7.7 Der Verein ist für alle Inhalte verantwortlich, die der Kunde auf mit Spielgruppe Online erstellten
        Formularseiten zugänglich hält oder speichert. Wir sind nicht verpflichtet, die Spielgruppe Online-Seiten des
        Kunden auf mögliche Rechtsverstöße zu überprüfen. Der Kunde verpflichtet sich, der Impressumspflicht (bzw. der
        jeweiligen gesetzlichen Pflicht im Land der Nutzung) selbständig nachzukommen.&nbsp;
      </p>

      <p>
        7.8 Dem Verein ist es untersagt, auf den Seiten von Spielgruppe Online Handlungen vorzunehmen, die gegen
        geltendes Recht verstoßen, die Rechte Dritter verletzen oder gegen die Grundsätze des Jugendschutzes verstoßen.
        Es ist untersagt, zu veröffentlichen, zu verbreiten, anzubieten und zu werben:
      </p>

      <ul>
        <li>
          <p>pornografische, unanständige oder obszöne Inhalte;</p>
        </li>
        <li>
          <p>
            gegen Jugendschutzgesetze, gegen Datenschutzrecht und/oder gegen sonstige rechtsverletzende und/oder
            betrügerische Inhalte, Dienste und/oder Produkte;
          </p>
        </li>
        <li>
          <p>
            Inhalte, die Krieg, Terror und andere Gewaltakte gegen Menschen oder Tiere verherrlichen oder verharmlosen;
          </p>
        </li>
        <li>
          <p>
            Inhalte, die andere Nutzer oder Dritte aufgrund von Alter, ethnischer oder sozialer Herkunft, Sprache,
            Religionszugehörigkeit, Behinderung, Geschlecht, sexueller Orientierung usw. beleidigen oder verleumden
          </p>
        </li>
        <li>
          <p>Inhalte, die geeignet sind, andere zu verleugnen, zu beleidigen, zu bedrohen oder zu verleumden;</p>
        </li>
        <li>
          <p>
            Inhalte oder Mitteilungen, die geeignet sind, Rassismus, Radikalismus, Faschismus, Fanatismus, Hass,
            körperliche oder geistige Gewalt oder rechtswidrige Handlungen zu fördern oder zu unterstützen, oder die
            anderweitig gegen die guten Sitten verstoßen;
          </p>
        </li>
        <li>
          <p>
            Inhalte, Dienste und/oder Produkte, die gesetzlich geschützt oder mit Rechten Dritter (z.B. Urheberrechten)
            belastet sind, ohne dazu nachweislich berechtigt zu sein.
          </p>
        </li>
        <li>
          <p>
            Diese Verpflichtungen gelten auch für die Verweise ("Hyperlinks"), die der Verein auf seiner
            Spielgruppen-Website auf diese Art von Inhalten auf externen Plattformen einfügt.
          </p>
        </li>
      </ul>

      <h2>8. Nutzungsgebühren und Zahlungsbedingungen</h2>

      <p>
        8.1 Nutzt der Kunde eine kostenpflichtige Version, so schuldet der Kunde der Webtie das vereinbarte monatlich
        wiederkehrende Nutzungsentgelt. Die Entgelte richten sich nach der gewählten Produktversion gemäß der zum
        Zeitpunkt des Vertragsschlusses gültigen Preisliste. Die auf der Produkt-Website festgelegten und
        veröffentlichten Preise gelten jeweils für eine Vertragslaufzeit. Soweit nicht anders angegeben, verstehen sich
        die Preise inklusive der jeweils gültigen Mehrwertsteuer.
      </p>

      <p>
        8.2 Webtie behält sich ausdrücklich das Recht vor, Preise für nachfolgende Vertragslaufzeiten zu ändern.
        Preisänderungen werden dem Kunden spätestens zwei (2) Monate vor Ablauf der aktuellen Vertragslaufzeit an die in
        Spielgruppe Online hinterlegte Clubadresse per E-Mail oder Briefpost mitgeteilt.
      </p>

      <p>
        8.3 Die Entgelte sind jeweils zu Beginn der Vertragslaufzeit fällig. Nach Ablauf der für die Leistung
        festgelegten Zeit, d.h. der vereinbarten Zahlungsfrist, gerät der Kunde automatisch und ohne Mahnung in Verzug.
        Soweit nichts anderes vereinbart ist, sind alle Rechnungen sofort nach Eingang beim Kunden zur Zahlung fällig.
      </p>

      <p>
        8.4 Webtie bietet eine kostenlose Version an. Sie ist kostenlos, hat aber im Vergleich zu einer
        kostenpflichtigen Game Group Online Version einen eingeschränkten Funktionsumfang.&nbsp;
      </p>

      <p>
        8.5 Webtie bietet auch eine Testversion an. Während des angegebenen Testzeitraums ist die Nutzung kostenlos.
        Nach Ablauf des Testzeitraums wird die Testversion ohne Kündigung durch den Kunden automatisch in die
        kostenpflichtige Version umgewandelt.
      </p>

      <p>8.6 Teilzahlungen werden zunächst auf ältere offene Forderungen von Webtie gegen den Kunden geleistet.</p>

      <h2>9. die Einstellung des Dienstes (Sperrung des Kundenzugangs)</h2>

      <p>
        9.1 Webtie ist berechtigt, die vom Kunden genutzte Leistung ganz oder teilweise mit sofortiger Wirkung
        einzustellen oder den Zugang des Kunden zu sperren, wenn und solange sich der Kunde in Zahlungsverzug befindet
        oder trotz Mahnung gegen vertragliche Pflichten verstößt.
      </p>

      <p>
        9.2 Eine Einstellung oder Sperrung ist auch möglich, wenn zu befürchten ist, dass (a) die Nutzung des Dienstes
        durch den Kunden oder die Daten des Kunden Rechte Dritter verletzen oder gegen Gesetz und gute Sitten verstoßen
        (vgl. Ziffer 7 dieser AGB), oder (b) das Verhalten des Kunden in irgendeiner Weise den Server der Webtie oder
        den Betrieb von Spielgruppe Online beeinträchtigt, oder (c) es sonst erforderlich ist, um Schaden abzuwenden.
        Die Dauer der Sperrung richtet sich in diesem Fall nach der Dauer der Klärung, ob ein Verstoß vorliegt oder nach
        der Dauer des Verstoßes zuzüglich einer angemessenen Wiederanlaufzeit. Soweit zumutbar und möglich, wird Webtie
        den Kunden vorab informieren.
      </p>

      <p>
        9.3 Dem Kunden stehen keine Schadensersatzansprüche infolge einer Sperrung oder Sperre zu. Der Kunde bleibt auch
        während der Sperrung zur Zahlung der Nutzungsentgelte verpflichtet.
      </p>

      <h2>10 Vertragslaufzeit, Kündigung</h2>

      <p>
        10.1 Der Vertrag beginnt, soweit nicht ausdrücklich etwas anderes vereinbart ist, mit der Übersendung einer
        Registrierungsbestätigung an den Kunden. Bei einer kostenpflichtigen Version beträgt die Vertragslaufzeit ein
        Jahr - Ziffer 3.2 bleibt unberührt - und verlängert sich jeweils um ein weiteres Jahr, wenn der Vertrag nicht
        mindestens einen (1) Monat vor Ablauf der Vertragslaufzeit gekündigt wird. Der Vertrag für eine kostenlose
        Version hat keine bestimmte Laufzeit.
      </p>

      <p>
        10.2 Das Recht zur fristlosen Kündigung des Vertrages aus wichtigem Grund bleibt unberührt. Ein solcher Grund,
        der Webtie zur außerordentlichen Kündigung berechtigt, liegt insbesondere vor, wenn der Kunde trotz Abmahnung
        gegen Bestimmungen dieser AGB verstößt, wenn der Kunde trotz Abmahnung seiner Entgeltpflicht nicht nachkommt,
        wenn der Kunde in Konkurs gerät oder wenn das Konkursverfahren mangels Masse eingestellt wird.
      </p>

      <p>
        10.3 Beruht eine fristlose Kündigung durch Webtie auf einer Vertragsverletzung durch den Kunden, so behält sie
        als Mindestschaden die vertragliche Vergütung, die ihr ohne die Kündigung zugestanden hätte. Der Anspruch auf
        Schadensersatz besteht nicht, wenn der Kunde die Pflichtverletzung nicht zu vertreten hat.
      </p>

      <p>
        10.4 Soweit es sich um unentgeltliche Leistungen (z.B. Free- oder Trial-Version) handelt, ist Webtie berechtigt,
        diese Leistungen mit einer Frist von sechs (6) Monaten in Textform (z.B. per E-Mail) zu kündigen. Von diesem
        Kündigungsrecht kann die Webtie z.B. bei einer 12-monatigen Inaktivität des Zugangs des Kunden Gebrauch machen.
        Die Webtie ist berechtigt, den Zugang und alle damit verbundenen Daten nach Vertragsende zu löschen. Im Falle
        einer kostenlosen Version kann der Kunde den Vertrag mit einer Frist von zwei (2) Wochen in Textform (z.B. per
        E-Mail) kündigen.
      </p>

      <h2>11. System- und Datensicherheit</h2>

      <p>
        11.1 Der Kunde bestätigt, dass er von Webtie auf die bestehenden Sicherheitsrisiken bei der Nutzung des
        Internets und der Internettechnologien hingewiesen wurde. Der Kunde ist für die Sicherheit der Systeme,
        Programme und Daten in seinem Einflussbereich selbst verantwortlich.
      </p>

      <p>
        11.2 Die Webtie wird angemessene, wirtschaftlich zumutbare Maßnahmen ergreifen, um die von ihr zu
        verantwortenden Leistungen und Systeme nach dem jeweiligen Stand der Technik gegen unbefugte Zugriffe oder
        Angriffe von außen zu schützen. Dabei übernimmt die Webtie keine wie auch immer geartete Garantie oder
        Gewährleistung für den Schutz vor unbefugten Zugriffen von außen.
      </p>

      <p>
        11.3 Die Daten des Kunden, die der Kunde auf dem Server der Webtie oder deren Subunternehmern speichert oder
        nutzt, werden von der Webtie täglich mit der gebotenen Sorgfalt gesichert. Im Falle eines Verlustes wird die
        Webtie die verlorenen Daten aus der letzten verfügbaren Sicherung wiederherstellen.
      </p>

      <p>
        11.4 Der Kunde wird darauf hingewiesen, dass Webtie alle zugänglichen Daten jederzeit aus technischer Sicht
        einsehen kann. Die Daten des Kunden werden von Webtie für die Dauer des Vertragsverhältnisses gespeichert,
        verarbeitet und ausgewertet, soweit dies zur Erfüllung des Vertragszwecks erforderlich ist.
      </p>

      <p>
        11.5 Die Webtie hat die von ihr eingesetzten Hilfspersonen auf den vertraulichen Umgang mit Daten verpflichtet.
        Darüber hinaus wird die Webtie ohne Zustimmung des Kunden keine Daten des Kunden an unbefugte Dritte
        weitergeben. Daten werden jedoch Dritten zugänglich gemacht, wenn Webtie hierzu gesetzlich oder aufgrund einer
        behördlichen oder gerichtlichen Anordnung verpflichtet ist. Die Webtie verpflichtet sich, mit allen Mitarbeitern
        und Unterauftragnehmern, die sie im Zusammenhang mit der Vorbereitung, Durchführung und Erfüllung dieses
        Vertrages einsetzt, eine inhaltsgleiche Regelung zu vereinbaren.
      </p>

      <p>
        11.6 Informationen über das Nutzungsverhalten (Art, Zeitpunkt, Anzahl und Dauer der Interaktionen mit
        Spielgruppe Online) werden zu Kontrollzwecken (Einhaltung der Vereinbarungen zwischen Kunde und webtie), zur
        Analyse für Produktverbesserungen und zu Marketingzwecken (Angabe von Funktionen) gespeichert, ggf. auch
        zusammen mit der Internetadresse (IP-Adresse) des Nutzers.
      </p>

      <p>
        11.7 Die Vertragspartner werden die jeweils erforderlichen Erklärungen zum Datenschutz abgeben und die insoweit
        erforderlichen ergänzenden Vereinbarungen treffen, soweit dies für Webtie zumutbar ist. Mit Kunden aus dem
        Europäischen Wirtschaftsraum wird Webtie eine Vereinbarung zur Auftragsverarbeitung (AVV) im Hinblick auf den
        Datenschutz abschließen. Im Falle von Widersprüchen zwischen diesen AGB und der AVV gehen die AVV vor.
      </p>

      <h2>12. Datenschutz</h2>

      <p>
        12.1 Soweit Webtie Zugriff auf personenbezogene Daten des Kunden oder aus dem Bereich des Kunden hat, wird
        Webtie ausschließlich als Auftragsverarbeiter tätig und verarbeitet und nutzt diese Daten nur für die
        Durchführung des Vertrages. Die Webtie wird die Weisungen des Kunden zum Umgang mit diesen Daten befolgen.
        Nachteilige Folgen solcher Weisungen für die Vertragserfüllung trägt der Kunde.&nbsp; Der Kunde wird mit der
        Webtie die Einzelheiten des Umgangs der Webtie mit den Daten des Kunden entsprechend den datenschutzrechtlichen
        Anforderungen vereinbaren.
      </p>

      <p>
        12.2 Der Kunde bleibt sowohl allgemein im Vertragsverhältnis als auch datenschutzrechtlich die verantwortliche
        Partei. Verarbeitet der Kunde im Zusammenhang mit dem Vertrag personenbezogene Daten (einschließlich der
        Erhebung und Nutzung), so steht er dafür ein, dass er dazu nach den geltenden Bestimmungen, insbesondere den
        datenschutzrechtlichen Bestimmungen, berechtigt ist und stellt Webtie im Falle eines Verstoßes von Ansprüchen
        Dritter frei.
      </p>

      <h2>13. Störungsmanagement</h2>

      <p>
        13.1 Bei einer nur unerheblichen Minderung der Tauglichkeit der Leistungen zum vertragsgemäßen Gebrauch stehen
        dem Kunden keine Ansprüche wegen Mängeln zu. Die verschuldensunabhängige Haftung von Webtie für Mängel, die
        bereits bei Vertragsschluss vorhanden waren, ist ausgeschlossen.
      </p>

      <p>
        13.2 Webtie nimmt Störungsmeldungen des Kunden entgegen, ordnet sie den vereinbarten Störungskategorien (Ziffer
        13.4) zu und führt auf der Grundlage dieser Zuordnung die vereinbarten Maßnahmen zur Analyse und Beseitigung von
        Störungen durch.
      </p>

      <p>
        13.3 Webtie nimmt während der üblichen Geschäftszeiten ordnungsgemäße Störungsmeldungen des Kunden entgegen und
        vergibt für jede Störungsmeldung eine Kennung. Auf Verlangen des Kunden wird Webtie den Eingang einer
        Störungsmeldung bestätigen, indem sie dem Kunden die zugewiesene Kennung mitteilt.
      </p>

      <p>
        13.4 Sofern nicht anders vereinbart, ordnet Webtie die eingegangenen Fehlermeldungen nach erster Prüfung einer
        der folgenden Kategorien zu:
      </p>

      <p>
        a) Schwerwiegende Störung: Die Störung beruht auf einem Fehler der vertraglichen Leistungen, der die Nutzung der
        vertraglichen Leistungen, insbesondere der Software, unmöglich macht oder nur mit erheblichen Einschränkungen
        zulässt. Der Kunde kann diese Störung nicht in zumutbarer Weise umgehen und daher unaufschiebbare Aufgaben nicht
        erledigen.
      </p>

      <p>
        b) Sonstige Störung: Die Störung beruht auf einem Mangel der vertraglichen Leistungen, der die Nutzung der
        vertraglichen Leistungen, insbesondere der Software, durch den Kunden in mehr als nur unerheblichem Maße
        einschränkt, ohne dass eine schwerwiegende Störung vorliegt.
      </p>

      <p>
        c) Sonstige Meldungen: Störungsmeldungen, die nicht in die Kategorien a) und b) fallen, werden den sonstigen
        Meldungen zugeordnet. Sonstige Meldungen werden von Webtie nur nach den dafür getroffenen Vereinbarungen
        bearbeitet.
      </p>

      <p>
        13.5 Bei Meldungen von schwerwiegenden Störungen und sonstigen Fehlfunktionen wird die Webtie auf der Grundlage
        der vom Kunden mitgeteilten Umstände so schnell wie möglich geeignete Maßnahmen einleiten, um zunächst die
        Ursache der Störung zu lokalisieren. Die Webtie wird geeignete Maßnahmen zur weiteren Analyse und Behebung der
        gemeldeten Störung einleiten oder - im Falle von Fremdsoftware - die Störungsmeldung zusammen mit ihren
        Analyseergebnissen an den Vertreiber oder Hersteller der Fremdsoftware mit der Bitte um Abhilfe weiterleiten.
        Die Webtie wird dem Kunden schnellstmöglich vorhandene Maßnahmen zur Umgehung oder Beseitigung einer Störung der
        vertraglichen Leistungen, insbesondere der überlassenen Software, wie Handlungsanweisungen oder Korrekturen der
        überlassenen Software, zur Verfügung stellen. Der Kunde wird solche Maßnahmen zur Fehlervermeidung oder
        -beseitigung ergreifen und verbleibende Fehler nach deren Umsetzung der Webtie erneut anzeigen.
      </p>

      <p>
        13.6 Die in der Benutzerdokumentation oder sonstigen Unterlagen enthaltenen technischen Daten, Spezifikationen
        und Leistungsbeschreibungen der Leistung stellen keine Garantien oder Zusicherungen seitens der Webtie dar.
        Insbesondere macht die Webtie keinerlei Zusagen über die Funktionsfähigkeit oder sonstige Wirtschaftlichkeit
        oder Vorteilhaftigkeit der Leistung und kann nicht gewährleisten, dass die Leistung in allen vom Kunden
        gewünschten Kombinationen mit beliebigen Daten, IT-Systemen und sonstigen Programmen unterbrechungs- und
        fehlerfrei genutzt werden kann, noch dass die Beseitigung eines Programmfehlers das Auftreten weiterer
        Programmfehler ausschließt.
      </p>

      <p>
        13.7 Mündliche Auskünfte und Zusagen, Prospekte und Werbeaussagen von Webtie, gleich welcher Art, sind
        freibleibend, sofern sie nicht ausdrücklich als verbindlich bezeichnet werden und stellen keine Zusicherung oder
        Garantie gleich welcher Art dar.
      </p>

      <p>13.8 Weitere Gewährleistungs- und Garantieansprüche sind ausdrücklich ausgeschlossen.</p>

      <h2>14. Haftung</h2>

      <p>
        14.1 Webtie haftet auf Schadensersatz oder Ersatz vergeblicher Aufwendungen, gleich aus welchem Rechtsgrund, nur
        in voller Höhe
      </p>

      <p>(a) bei Vorsatz und arglistiger Täuschung; und/oder</p>

      <p>(b) wenn eine Sicherheit geleistet wurde, falls vorhanden; und/oder</p>

      <p>c) bei schuldhafter Verletzung von Leben, Körper oder Gesundheit und/oder</p>

      <p>d) für Ansprüche aus der Produkthaftung.</p>

      <p>
        14.2 Soweit kein Fall der vorstehenden Ziffer 14.1 vorliegt, haftet Webtie auf Schadensersatz, gleich aus
        welchem Rechtsgrund, nur bei fahrlässiger Verletzung wesentlicher Vertragspflichten, begrenzt auf den bei dem
        jeweiligen Vertragsschluss vorhersehbaren und vertragstypischen Schaden. Wesentliche Vertragspflichten sind
        solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren
        Einhaltung der Vertragspartner regelmäßig vertrauen darf. Im Übrigen ist die Haftung von Webtie insgesamt für
        alle Schadensfälle pro Vertragsjahr auf den doppelten Betrag des vereinbarten jährlichen Nutzungsentgelts und
        bei der kostenlosen Version auf das nächsthöhere jährliche Nutzungsentgelt begrenzt.
      </p>

      <p>
        14.3 Webtie haftet nicht für mittelbare Schäden, Folgeschäden oder sonstige Schäden, die dem Kunden durch
        technische Mängel, Sicherheitsmängel, Störungen von Drittunternehmen, mit denen Webtie zusammenarbeitet oder von
        denen sie abhängig ist (z.B. Netzbetreiber, Serverhosting), entstehen. Indirekte Schäden in diesem Sinne sind
        insbesondere auch entgangener Gewinn.
      </p>

      <p>
        14.4 Die vorliegenden Haftungsbeschränkungen und Haftungsausschlüsse gelten sowohl für vertragliche als auch für
        außervertragliche oder vertragsähnliche Ansprüche.
      </p>

      <p>
        14.5 Soweit die Haftung der Webtie gegenüber dem Kunden beschränkt oder ausgeschlossen ist, gilt dies
        entsprechend für die gesetzlichen Vertreter, Angestellten, freien Mitarbeiter und sonstigen Erfüllungsgehilfen
        der Webtie.
      </p>

      <h2>15. Verjährungsfrist</h2>

      <p>15.1 Die Verjährungsfrist wird wie folgt festgelegt</p>

      <p>
        a) bei Sachmängeln für Ansprüche auf Rückzahlung des Kaufpreises aus Rücktritt oder Minderung ein Jahr ab
        Ablieferung der Software, bei ordnungsgemäß gerügten Mängeln jedoch nicht weniger als drei Monate ab Abgabe der
        wirksamen Rücktritts- oder Minderungserklärung;
      </p>

      <p>b) ein Jahr für sonstige Ansprüche aus Sachmängeln;</p>

      <p>c) zwei Jahre bei Ansprüchen aus Rechtsmängeln,</p>

      <p>
        d) bei Ansprüchen auf Schadensersatz oder Ersatz vergeblicher Aufwendungen, die nicht auf Sach- oder
        Rechtsmängeln beruhen, zwei Jahre; die Frist beginnt mit dem Zeitpunkt, in dem der Käufer von den
        anspruchsbegründenden Umständen Kenntnis erlangt hat oder ohne grobe Fahrlässigkeit hätte erlangen müssen.
      </p>

      <p>
        15.2 Die Verjährungsfrist beginnt spätestens mit Ablauf der gesetzlichen Höchstfristen zu laufen. Die
        vorstehende Verjährungsfrist gilt nicht bei Schadens- und Aufwendungsersatzansprüchen aus Vorsatz, grober
        Fahrlässigkeit, Garantie, Arglist und in den in Ziffer 14.1 genannten Fällen.
      </p>

      <h2>16. Schlussbestimmungen</h2>

      <p>
        16.1 Mit Ausnahme der Abtretung von Geldforderungen können Rechte und Pflichten aus dem Vertrag vom Kunden nur
        mit schriftlicher Zustimmung von Webtie auf Dritte übertragen oder abgetreten werden. Insbesondere ist es dem
        Kunden untersagt, von der Webtie bezogene Leistungen (z.B. Speicherplatz) an Dritte unterzuvermieten.
        Ausgenommen von dieser Regelung ist die Übertragung des Vertrages durch den Kunden auf seinen Rechtsnachfolger.
      </p>

      <p>
        16.2 Alle Anzeigen und Erklärungen im Zusammenhang mit diesen AGB und den Vertragsbedingungen bedürfen zumindest
        der Textform (dauerhafte, lesbare Erklärung, in der der Erklärende und der Kunde (z.B. der Verein), für den er
        handelt, namentlich genannt sind), soweit nicht ausdrücklich eine andere Form zugelassen oder vorgeschrieben
        ist. Solche Erklärungen können u.a. über die entsprechenden Funktionen nach der Registrierung bei Spielgruppe
        Online abgegeben werden. Die Webtie behält sich vor, vom Kunden eine schriftliche Bestätigung wichtiger
        Erklärungen sowie einen entsprechenden Berechtigungsnachweis (z.B. Auszug aus dem Vereinsregister,
        Handelsregister oder eine Vollmacht) zu verlangen, wofür der Kunde die Kosten trägt.
      </p>

      <p>
        16.3 Webtie behält sich ausdrücklich das Recht vor, diese AGB jederzeit in angemessener Weise zu ändern. Die
        neuen AGB werden dem Kunden mindestens vier (4) Wochen vor ihrem geplanten Inkrafttreten in Textform (z.B. per
        E-Mail) mitgeteilt. Will ein Kunde die Änderungen nicht akzeptieren, kann er den Vertrag über die Nutzung der
        Software vor dem geplanten Inkrafttreten der Änderungen außerordentlich fristlos schriftlich oder in Textform
        kündigen. Die geänderten AGB gelten als angenommen, ohne dass es eines vorherigen Widerspruchs oder einer
        Kündigung des Vertrages bedarf. Hierauf wird der Kunde in der Änderungsmitteilung gesondert hingewiesen.
      </p>

      <p>
        16.4 Es gilt ausschliesslich schweizerisches Recht unter Ausschluss des internationalen Vertragsrechts und des
        Kollisionsrechts (IPRG) sowie des UN-Kaufrechts. Erfüllungsort für die gegenseitigen Verpflichtungen ist
        Windisch. Ist der Kunde Kaufmann, eine juristische Person des öffentlichen Rechts oder ein
        öffentlich-rechtliches Sondervermögen, so ist ausschließlicher Gerichtsstand ebenfalls Windisch. Webtie ist
        jedoch auch berechtigt, an jedem anderen national oder international zuständigen Gericht zu klagen.
      </p>

      <p>Webtie.ch</p>

      <p>Bahnhofstrasse 6a</p>

      <p>5210 Windisch</p>
    </Container>
  );
};
