import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { Alert, Col, Row } from "react-bootstrap";
import styled from "styled-components";

import { ReactComponent as BoySvg } from "./assets/boy.svg";
import { ReactComponent as DownloadSvg } from "./assets/download-svgrepo-com.svg";
import { ReactComponent as DocumentSvg } from "./assets/document-svgrepo-com.svg";
import { ReactComponent as ToolsSvg } from "./assets/tools-svgrepo-com.svg";
import { ReactComponent as GeniusSvg } from "./assets/genius-svgrepo-com.svg";
import { useAuthentication } from "../../hooks/useAuthentication";
import useSubscription from "../../hooks/useSubscription";
import { ContentLayout } from "../common/Layout";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

const VerticalLine = styled.div`
  border-left: 2px solid #d4d5d7;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
`;

const TopDot = styled.div`
  height: 13px;
  width: 13px;
  border: 2px solid #1991ac;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  background-color: #fff;
  top: 0;
  z-index: 1;
`;

const CenterDot = styled.div`
  height: 13px;
  width: 13px;
  border: 2px solid #1991ac;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  background-color: #fff;
  top: 50%;
  z-index: 1;
`;

const BottomDot = styled.div`
  height: 13px;
  width: 13px;
  border: 2px solid #1991ac;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  background-color: #fff;
  top: 100%;
  z-index: 1;
`;

export const SpielgruppePlus: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  const subscription = useSubscription(userId);
  useAuthentication(props.isAuthenticated);

  const isPlusSubscription = subscription?.role === "Spielgruppe Plus";

  return (
    <ContentLayout title={"Spielgruppe Plus"} siteTitle={"Spielgruppe Onlie - Spielgruppe Plus"}>
      {isPlusSubscription && (
        <Alert className={"mt-5"} variant={"info"}>
          <b>Sie besitzen eine aktive Spielgruppe Plus Mitgliedschaft.</b>
          <br />
        </Alert>
      )}
      <Row className={"mt-5"}>
        <Col sm={12} md={9}>
          <Row>
            <Col md={5} className={"pb-5"}>
              <h3>Anmeldungsexport</h3>
              <p>Eingegangene Anmeldungen können ganz bequem als Excel-Liste exportiert werden.</p>
            </Col>
            <Col md={2} className={"mt-5 pb-5 d-none d-md-block"}>
              <TopDot />
              <VerticalLine />
            </Col>
            <Col md={5} className={"mt-5 d-none d-md-flex"}>
              <DownloadSvg />
            </Col>
          </Row>
          <Row>
            <Col className={"flex-row-reverse align-items-center d-none d-md-flex"} md={5}>
              <DocumentSvg />
            </Col>
            <Col className={"d-none d-md-block"}>
              <CenterDot />
              <VerticalLine />
            </Col>
            <Col md={5} className={"py-5"}>
              <h3>Zusätzliche Formularfelder</h3>
              <p>
                Diverse zusätzliche Feldtypen stehen zur Verfügung. E-Mail, Ganzzahlen, Checkboxen, Zwischentitel, etc.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={5} className={"py-5"}>
              <h3>Individuelle E-Mail-Gestaltung</h3>
              <p>
                Gestalten Sie Ihre generierten E-Mails nach belieben. Passen Sie Text und Farben an Ihre Spielgruppe an.
              </p>
            </Col>
            <Col className={"d-none d-md-block"}>
              <CenterDot />
              <VerticalLine />
            </Col>
            <Col md={5} className={"flex-row align-items-center d-none d-md-flex"}>
              <ToolsSvg />
            </Col>
          </Row>
          <Row>
            <Col md={5} className={"flex-row-reverse align-items-end mb-4 d-none d-md-flex"}>
              <GeniusSvg />
            </Col>
            <Col className={"mb-5 d-none d-md-block"}>
              <BottomDot />
              <VerticalLine />
            </Col>
            <Col md={5} className={"pt-5"}>
              <h3>Viele weitere Funktionen folgen</h3>
              <p>Wir entwickeln laufend zusätzliche Module, welche unseren Spielgruppen das Leben erleichtern.</p>
            </Col>
          </Row>
          <Row className={"pt-5"}>
            <Col md={4} />
            <Col md={4}>
              Melden Sie sich bei uns unter <strong>info@webtie.ch </strong> um auf <strong>Spielgruppe Plus</strong> zu
              wechseln.
            </Col>
            <Col md={4} />
          </Row>
        </Col>
        <Col sm={12} md={3} className={"d-none d-md-block"}>
          <BoySvg />
        </Col>
      </Row>
    </ContentLayout>
  );
};
