import { RegistrationDto } from "@webtie-ch/form-widget-library";

export class FirebaseApi {
  constructor(
    private readonly createRegistrationApi: string,
    private readonly updateRegistrationApi: string,
    private readonly deleteRegistrationApi: string,
    private readonly deleteAllRegistrationApi: string
  ) {}

  public async createRegistration(userId: string, formData: unknown, authToken: string): Promise<string> {
    const registrationDto: RegistrationDto = {
      formData: formData
    };

    const url = this.createRegistrationApi.replace("userId", userId);
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(registrationDto),
      headers: { "Content-Type": "application/json; charset=UTF-8", Authorization: `Bearer ${authToken}` }
    });

    if (response.status !== 200) {
      throw new Error(await response.text());
    }
    const body = await response.json();
    return body.registrationId;
  }

  public async updateRegistration(
    userId: string,
    registrationId: string,
    formData: unknown,
    authToken: string
  ): Promise<void> {
    const registrationDto: RegistrationDto = {
      formData: formData
    };

    const url = this.updateRegistrationApi.replace("userId", userId).replace("registrationId", registrationId);

    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(registrationDto),
      headers: { "Content-Type": "application/json; charset=UTF-8", Authorization: `Bearer ${authToken}` }
    });

    if (response.status !== 204) {
      throw new Error(await response.text());
    }
  }

  public async deleteRegistration(userId: string, registrationId: string, authToken: string): Promise<void> {
    const url = this.deleteRegistrationApi.replace("userId", userId).replace("registrationId", registrationId);

    const response = await fetch(url, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${authToken}` }
    });

    if (response.status !== 204) {
      throw new Error(await response.text());
    }
  }

  public async deleteAllRegistrations(userId: string, authToken: string): Promise<void> {
    const url = this.deleteAllRegistrationApi.replace("userId", userId);

    const response = await fetch(url, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${authToken}` }
    });

    if (response.status !== 204) {
      throw new Error(await response.text());
    }
  }
}
