import { useState } from "react";

import { firebaseAppAuth } from "../lib/firebase";

interface ReturnType {
  resetPassword: (email: string) => Promise<void>;
  isLoading: boolean;
}

export const useAuthResetPassword = (): ReturnType => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const resetPassword = async (email: string): Promise<void> => {
    setLoading(true);
    try {
      await firebaseAppAuth.sendPasswordResetEmail(email);
    } finally {
      setLoading(false);
    }
  };

  return {
    resetPassword: resetPassword,
    isLoading: isLoading
  };
};
