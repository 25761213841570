import { MessagesIndex } from "../../models/error/MessagesIndex";

export const firebaseErrorMessages: MessagesIndex = {
  "email-already-in-use": "Diese Emailadresse wird bereits verwendet."
};

export function getErrorByCode(code: string): string {
  code = code.split("/")[1];
  if (firebaseErrorMessages[code]) {
    return firebaseErrorMessages[code];
  } else {
    return "Unbekannter Fehler ist aufgetreten \n Fehlercode: " + code;
  }
}
