import { useEffect, useState } from "react";

import FirebaseService from "../actions/firebase/FirebaseActions";

export const useSchemaProperties = (userId: string): [string[], boolean, boolean] => {
  const [schemaProperties, setSchemaProperties] = useState<string[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setError(false);
    setLoading(true);
    FirebaseService.getSchemaProperties(userId)
      .then((properties) => {
        setSchemaProperties(properties);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [userId, setSchemaProperties, setLoading]);

  return [schemaProperties, isLoading, error];
};
