import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import React, { FunctionComponent } from "react";

import Limit from "../../models/limit/Limit";

interface Props {
  width: number;
  limit: Limit;
}

export const RegistrationsPerLimitChart: FunctionComponent<Props> = (props: Props) => (
  <RadialBarChart
    width={props.width}
    height={props.width}
    cx={props.width / 2}
    cy={props.width / 2}
    innerRadius={(props.width / 5) * 2}
    outerRadius={(props.width / 5) * 3}
    barSize={5}
    data={[{ name: props.limit.property, value: props.limit.taken }]}
    startAngle={90}
    endAngle={-270}>
    <PolarAngleAxis type="number" domain={[0, props.limit.limit]} angleAxisId={0} tick={false} />
    <RadialBar background dataKey="value" cornerRadius={props.width / 2} fill="#1a9cb5" />
    <text
      x={props.width / 2}
      y={props.width / 2}
      textAnchor="middle"
      dominantBaseline="middle"
      className="progress-label">
      {props.limit.taken} / {props.limit.limit}
    </text>
  </RadialBarChart>
);
