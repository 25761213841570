import { useEffect, useState } from "react";

import FirebaseService from "../actions/firebase/FirebaseActions";
import { FirebaseSnapshotMapper } from "../actions/firebase/FirebaseSnapshotMapper";
import Limit from "../models/limit/Limit";

export const useLimits = (userId: string): [Limit[], boolean, boolean?] => {
  const [limits, setLimits] = useState<Limit[]>([]);
  const [error, setError] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = FirebaseService.streamLimits(userId, {
      next: (querySnapshot) => {
        const updatedRegistrations = FirebaseSnapshotMapper.toLimits(querySnapshot);
        setLimits(updatedRegistrations);
        setLoading(false);
      },
      error: () => {
        setError(true);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [userId, setLimits, setLoading]);

  return [limits, isLoading, error];
};
