import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import HeaderRow from "../common/HeaderRow";

export const Datenschutz: FunctionComponent = () => {
  return (
    <Container className={"h-100 py-5"}>
      <div>
        <Helmet>
          <title>Spielgruppe Online - Datenschutz</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <HeaderRow title={"Datenschutzerklärung"} headingLevel={1} />
        <Row className={"justify-content-center py-3"}>
          <Col>
            <div>
              <p>
                Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung
                (DSGVO), ist:
              </p>
              <br />
              <p>
                Mauro Stehle, Sven Humbel
                <br />
                Bahnhofstrasse 6a
                <br />
                5210 Windisch
              </p>
              <p>
                Kontakt aufnehmen: webtie.ch/#contact
                <br />
                WebSite: https://portal.spielgruppe.online/{" "}
              </p>
              <p>&nbsp;</p>
              <h2>Allgemeiner Hinweis</h2>{" "}
              <p>
                Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und den datenschutzrechtlichen Bestimmungen
                des Bundes (Datenschutzgesetz, DSG{/*DSG*/}) hat jede Person Anspruch auf Schutz ihrer Privatsphäre
                sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Die Betreiber dieser Seiten nehmen den Schutz
                Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
              </p>
              <p>
                In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor
                fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.
              </p>
              <p>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                nicht möglich.
              </p>
              <p>
                Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten
                gemäss der nachfolgenden Beschreibung einverstanden. Diese Website kann grundsätzlich ohne Registrierung
                besucht werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen
                Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten
                unmittelbar auf Ihre Person bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder
                E-Mail-Adresse werden soweit möglich auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt
                keine Weitergabe der Daten an Dritte.
              </p>
              <p>&nbsp;</p>
              <h2>Datenschutzerklärung für Cookies</h2>{" "}
              <p>
                Diese Website verwendet Cookies. Cookies sind Textdateien, die Daten von besuchten Websites oder Domains
                enthalten und von einem Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in
                erster Linie dazu, die Informationen über einen Benutzer während oder nach seinem Besuch innerhalb eines
                Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen auf einer
                Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören. Zu
                dem Begriff der Cookies zählen wir ferner andere Technologien, die die gleichen Funktionen wie Cookies
                erfüllen (z.B. wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert werden, auch
                als "Nutzer-IDs" bezeichnet)
              </p>{" "}
              <p>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</p>{" "}
              <ul>
                {" "}
                <li>
                  <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies)</strong>: Temporäre Cookies werden
                  spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und seinen Browser geschlossen
                  hat.
                </li>{" "}
                <li>
                  <strong>Permanente Cookies</strong>: Permanente Cookies bleiben auch nach dem Schliessen des Browsers
                  gespeichert. So kann beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt
                  angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die Interessen von
                  Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken verwendet werden, in einem solchen Cookie
                  gespeichert werden.
                </li>{" "}
                <li>
                  <strong>First-Party-Cookies</strong>: First-Party-Cookies werden von uns selbst gesetzt.
                </li>{" "}
                <li>
                  <strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>: Drittanbieter-Cookies werden
                  hauptsächlich von Werbetreibenden (sog. Dritten) verwendet, um Benutzerinformationen zu verarbeiten.
                </li>{" "}
                <li>
                  <strong>Notwendige (auch: essenzielle oder unbedingt erforderliche) Cookies</strong>: Cookies können
                  zum einen für den Betrieb einer Webseite unbedingt erforderlich sein (z.B. um Logins oder andere
                  Nutzereingaben zu speichern oder aus Gründen der Sicherheit).
                </li>{" "}
                <li>
                  <strong>Statistik-, Marketing- und Personalisierung-Cookies</strong>: Ferner werden Cookies im
                  Regelfall auch im Rahmen der Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines
                  Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf
                  einzelnen Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den Nutzern
                  z.B. Inhalte anzuzeigen, die ihren potenziellen Interessen entsprechen. Dieses Verfahren wird auch als
                  "Tracking", d.h., Nachverfolgung der potenziellen Interessen der Nutzer bezeichnet. Soweit wir Cookies
                  oder "Tracking"-Technologien einsetzen, informieren wir Sie gesondert in unserer Datenschutzerklärung
                  oder im Rahmen der Einholung einer Einwilligung.
                </li>{" "}
              </ul>{" "}
              <p>
                Hinweise zu Rechtsgrundlagen: Auf welcher Rechtsgrundlage wir Ihre personenbezogenen Daten mithilfe von
                Cookies verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und Sie
                in die Nutzung von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die
                erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage
                unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes
                und dessen Verbesserung) verarbeitet oder, wenn der Einsatz von Cookies erforderlich ist, um unsere
                vertraglichen Verpflichtungen zu erfüllen.
              </p>{" "}
              <p>
                Speicherdauer: Sofern wir Ihnen keine expliziten Angaben zur Speicherdauer von permanenten Cookies
                mitteilen (z. B. im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die Speicherdauer
                bis zu zwei Jahre betragen kann.
              </p>{" "}
              <p>
                Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): Abhängig davon, ob die Verarbeitung auf
                Grundlage einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit,
                eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer Daten durch Cookie-Technologien zu
                widersprechen (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst mittels
                der Einstellungen Ihres Browsers erklären, z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei
                hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden kann). Ein
                Widerspruch gegen den Einsatz von Cookies zu Zwecken des Onlinemarketings kann auch mittels einer
                Vielzahl von Diensten, vor allem im Fall des Trackings, über die Webseiten https://optout.aboutads.info
                und https://www.youronlinechoices.com/ erklärt werden. Daneben können Sie weitere Widerspruchshinweise
                im Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies erhalten.
              </p>{" "}
              <p>
                Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: Wir setzen ein Verfahren zum
                Cookie-Einwilligungs-Management ein, in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von
                Cookies, bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und
                Anbieter eingeholt sowie von den Nutzern verwaltet und widerrufen werden können. Hierbei wird die
                Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und die
                Einwilligung entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung kann
                serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer
                Technologien) erfolgen, um die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können.
                Vorbehaltlich individueller Angaben zu den Anbietern von Cookie-Management-Diensten gelten die folgenden
                Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei Jahren betragen. Hierbei wird ein
                pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite
                der Einwilligung (z. B. welche Kategorien von Cookies und/oder Diensteanbieter) sowie dem Browser,
                System und verwendeten Endgerät gespeichert.
              </p>{" "}
              <ul>
                {" "}
                <li>
                  Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                  Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                </li>{" "}
                <li>Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>{" "}
                <li>
                  Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs.
                  1 S. 1 lit. f. DSGVO).
                </li>{" "}
              </ul>{" "}
              <p>&nbsp;</p>
              <h2>Datenschutzerklärung für SSL-/TLS-Verschlüsselung</h2>
              <p>
                Diese Website nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie
                zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-/TLS-Verschlüsselung.
                Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf
                "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
              </p>{" "}
              <p>
                Wenn die SSL bzw. TLS Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
                von Dritten mitgelesen werden.
              </p>
              <p>&nbsp;</p>
              <h2>Datenschutzerklärung für Server-Log-Files</h2>
              <p>
                Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten Server-Log
                Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
              </p>{" "}
              <ul>
                {" "}
                <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
                <li>Hostname des zugreifenden Rechners</li> <li>Uhrzeit der Serveranfrage</li>{" "}
              </ul>{" "}
              <p>
                Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen
                Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zuprüfen, wenn uns
                konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
              </p>
              <p>&nbsp;</p>
              <h2>Dienste von Dritten</h2>{" "}
              <p>
                Diese Website verwenden allenfalls Google Maps für das Einbetten von Karten, Google Invisible reCAPTCHA
                für den Schutz gegen Bots und Spam sowie YouTube für das Einbetten von Videos.
              </p>
              <p>
                Diese Dienste der amerikanischen Google LLC verwenden unter anderem Cookies und infolgedessen werden
                Daten an Google in den USA übertragen, wobei wir davon ausgehen, dass in diesem Rahmen kein
                personenbezogenes Tracking allein durch die Nutzung unserer Website stattfindet.
              </p>
              <p>
                Google hat sich verpflichtet, einen angemessenen Datenschutz gemäss dem amerikanisch-europäischen und
                dem amerikanisch-schweizerischen Privacy Shield zu gewährleisten.
              </p>
              <p>
                Weitere Informationen finden sich in der{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener"
                  style={{ color: "inherit" }}>
                  Datenschutzerklärung von Google
                </a>
                .
              </p>
              <p>&nbsp;</p>
              <h2>Datenschutzerklärung für Kontaktformular</h2>
              <p>
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
                inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall
                von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
              </p>
              <p>&nbsp;</p>
              <h2>Datenschutzerklärung für Spielgruppen-Formular</h2>
              <p>
                Wenn Sie uns per Spielgruppen-Formular Anfragen zukommen lassen, werden Ihre Angaben aus dem Formular
                bei uns in der Datenbank gespeichert, bis Sie diese wieder explizit löschen oder Ihr Kundenkonto
                auflösen. Nur Sie und Administratoren der Webseite verfügen über Zugriffsrechte. Diese Daten geben wir
                nicht ohne Ihre Einwilligung weiter.
              </p>
              <p>&nbsp;</p>
              <h2>Kostenpflichtige Leistungen</h2>{" "}
              <p>
                Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B.
                Zahlungsangaben, um Ihre Bestellung resp. Ihren Auftrag ausführen zu können. Wir speichern diese Daten
                in unseren Systemen, bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.
              </p>
              <p>&nbsp;</p>
              <h2>Google Ads</h2>{" "}
              <p>
                Diese Website nutzt das Google Conversion-Tracking. Sind Sie über eine von Google geschaltete Anzeige
                auf unsere WebSite gelangt, wird von Google Ads ein Cookie auf Ihrem Rechner gesetzt. Das Cookie für
                Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google geschaltete Anzeige klickt. Diese
                Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung.
                Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist noch nicht abgelaufen, können wir
                und Google erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet
                wurde. Jeder Google Ads-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Websites
                von Ads-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen
                dazu, Conversion-Statistiken für Ads-Kunden zu erstellen, die sich für Conversion-Tracking entschieden
                haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer
                mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine
                Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
              </p>{" "}
              <p>
                Möchten Sie nicht am Tracking teilnehmen, können Sie das hierfür erforderliche Setzen eines Cookies
                ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert
                oder Ihren Browser so einstellen, dass Cookies von der Domain «googleleadservices.com» blockiert werden.
              </p>{" "}
              <p>
                Bitte beachten Sie, dass Sie die Opt-out-Cookies nicht löschen dürfen, solange Sie keine Aufzeichnung
                von Messdaten wünschen. Haben Sie alle Ihre Cookies im Browser gelöscht, müssen Sie das jeweilige
                Opt-out Cookie erneut setzen.
              </p>
              <p>&nbsp;</p>
              <h2>Datenschutzerklärung für Google Analytics</h2>
              <p>
                Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited. Wenn der
                Verantwortliche für die Datenverarbeitung auf dieser Website ausserhalb des Europäischen
                Wirtschaftsraumes oder der Schweiz sitzt, dann erfolgt die Google Analytics Datenverarbeitung durch
                Google LLC. Google LLC und Google Ireland Limited werden nachfolgend «Google» genannt.
              </p>
              <p>
                Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter
                ausgestalten. Diese Website verwendet Google Analytics zudem für eine geräteübergreifende Analyse von
                Besucherströmen, die über eine User-ID durchgeführt wird. Sofern Sie über ein Google-Benutzerkonto
                verfügen, können Sie in den dortigen Einstellungen unter «Meine Daten», «persönliche Daten» die
                geräteübergreifende Analyse Ihrer Nutzung deaktivieren.
              </p>{" "}
              <p>
                Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Die im Rahmen
                von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                zusammengeführt. Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code
                «_anonymizeIp();» erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen zu gewährleisten.
                Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit
                ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also
                sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.
              </p>{" "}
              <p>
                Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und
                dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre
                Nutzung der Website auszuwerten, um Reports über die Websitenaktivitäten zusammenzustellen und um
                weitere mit der Websitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
                Websitenbetreiber zu erbringen.
              </p>{" "}
              <p>
                Google Analytics verwendet Cookies. Die durch den Cookie erzeugten Informationen über Ihre Benutzung
                dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort
                gespeichert. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
                nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus
                die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
                Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das
                unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:{" "}
                <a href="https://www.swissanwalt.ch/de/gaoptout.aspx" target="_blank" rel="noopener">
                  Google Analytics deaktivieren
                </a>
                .
              </p>{" "}
              <p>
                Ausserdem können Sie die Nutzung von Google Analytics auch verhindern, indem sie auf diesen Link
                klicken:{" "}
                <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener">
                  Google Analytics deaktivieren
                </a>
                . Hierdurch wird ein sog. opt-out Cookie auf ihrem Datenträger gespeichert, der die Verarbeitung
                personenbezogener Daten durch Google Analytics verhindert. Bitte beachten Sie, dass bei einem Löschen
                sämtlicher Cookies auf Ihrem Endgerät auch diese Opt-out-Cookies gelöscht werden, d.h., dass Sie erneut
                die Opt-out-Cookies setzen müssen, wenn Sie weiterhin diese Form der Datenerhebung verhindern wollen.
                Die Opt-out-Cookies sind pro Browser und Rechner/Endgerät gesetzt und müssen daher für jeden Browser,
                Rechner oder anderes Endgerät gesondert aktiviert werden.
              </p>
              <p>&nbsp;</p> <h2>Datenschutzerklärung für die Nutzung von Google Web Fonts</h2>
              <p>
                Diese Website nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
                bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
                Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wenn Ihr Browser Web Fonts nicht
                unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
              </p>
              <p>
                Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
                <a href="https://developers.google.com/fonts/faq" target="_blank" style={{ color: "inherit" }}>
                  https://developers.google.com/fonts/faq
                </a>{" "}
                und in der Datenschutzerklärung von Google:{" "}
                <a href="https://www.google.com/policies/privacy/" target="_blank" style={{ color: "inherit" }}>
                  https://www.google.com/policies/privacy/
                </a>
              </p>
              <p>&nbsp;</p>
              <h2>Externe Zahlungsdienstleister</h2>{" "}
              <p>
                Diese Website setzt externe Zahlungsdienstleister ein, über deren Plattformen die Nutzer und wir
                Zahlungstransaktionen vornehmen können. Zum Beispiel über
              </p>
              <ul>
                <li>PostFinance (https://www.postfinance.ch/de/detail/rechtliches-barrierefreiheit.html)</li>
                <li>Visa (https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html)</li>
                <li>Mastercard (https://www.mastercard.ch/de-ch/datenschutz.html)</li>
                <li>American Express (https://www.americanexpress.com/de/legal/online-datenschutzerklarung.html)</li>
                <li>Paypal (https://www.paypal.com/de/webapps/mpp/ua/privacy-full)</li>
                <li>Bexio AG (https://www.bexio.com/de-CH/datenschutz)</li>
                <li>Payrexx AG (https://www.payrexx.ch/site/assets/files/2592/datenschutzerklaerung.pdf)</li>
                <li>Apple Pay (https://support.apple.com/de-ch/ht203027)</li>
                <li>Stripe (https://stripe.com/ch/privacy)</li>
                <li>Klarna (https://www.klarna.com/de/datenschutz/)</li>
                <li>Skrill (https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/)</li>
                <li>Giropay (https://www.giropay.de/rechtliches/datenschutzerklaerung) etc.</li>
              </ul>{" "}
              <p>
                Im Rahmen der Erfüllung von Verträgen setzen wir die Zahlungsdienstleister auf Grundlage der
                schweizerischen Datenschutzverordnung sowie und soweit nötig, des Art. 6 Abs. 1 lit. b. EU-DSGVO ein. Im
                Übrigen setzen wir externe Zahlungsdienstleister auf Grundlage unserer berechtigten Interessen gem.
                schweizerischer Datenschutzverordnung sowie und soweit nötig, gem. Art. 6 Abs. 1 lit. f. EU-DSGVO ein,
                um unseren Nutzern effektive und sichere Zahlungsmöglichkeit zu bieten.
              </p>{" "}
              <p>
                Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name und
                die Adresse, Bankdaten, wie u.a. Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen
                sowie die Vertrags-, Summen und empfängerbezogenen Angaben. Die Angaben sind erforderlich, um die
                Transaktionen durchzuführen. Die eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister
                verarbeitet und bei diesen gespeichert. Wir als Betreiber erhalten keinerlei Informationen zu (Bank-)
                Konto oder Kreditkarte, sondern lediglich Informationen zur Bestätigung (Annahme) oder Ablehnung der
                Zahlung. Unter Umständen werden die Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien
                übermittelt. Diese Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf
                die AGB und Datenschutzhinweise der Zahlungsdienstleister.
              </p>{" "}
              <p>
                Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen
                Zahlungsdienstleister, welche innerhalb der jeweiligen Website, bzw. Transaktionsapplikationen abrufbar
                sind. Wir verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung von Widerrufs-,
                Auskunfts- und anderen Betroffenenrechten.
              </p>
              <p>&nbsp;</p>
              <h2>Hinweis zur Datenweitergabe in die USA</h2>{" "}
              <p>
                Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese
                Tools aktiv sind, können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen
                weitergegeben werden. Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des
                EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an
                Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten.
                Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern
                befindlichen Daten zu überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf
                diese Verarbeitungstätigkeiten keinen Einfluss.
              </p>
              <p>&nbsp;</p>
              <h2>Urheberrechte</h2>{" "}
              <p>
                Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website,
                gehören ausschliesslich dem Betreiber dieser Website oder den speziell genannten Rechteinhabern. Für die
                Reproduktion von sämtlichen Dateien, ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus
                einzuholen.
              </p>
              <p>
                Wer ohne Einwilligung des jeweiligen Rechteinhabers eine Urheberrechtsverletzung begeht, kann sich
                strafbar und allenfalls schadenersatzpflichtig machen.
              </p>
              <p>&nbsp;</p>
              <h2>Allgemeiner Haftungsausschluss</h2>{" "}
              <p>
                Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, unser
                Informationsangebot aktuell, inhaltlich richtig und vollständig anzubieten. Trotzdem kann das Auftreten
                von Fehlern nicht völlig ausgeschlossen werden, womit wir keine Garantie für Vollständigkeit,
                Richtigkeit und Aktualität von Informationen auch journalistisch-redaktioneller Art übernehmen können.
                Haftungsansprüche aus Schäden materieller oder ideeller Art, die durch die Nutzung der angebotenen
                Informationen verursacht wurden, sind ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob
                fahrlässiges Verschulden vorliegt.
              </p>
              <p>
                Der Herausgeber kann nach eigenem Ermessen und ohne Ankündigung Texte verändern oder löschen und ist
                nicht verpflichtet, Inhalte dieser Website zu aktualisieren. Die Benutzung bzw. der Zugang zu dieser
                Website geschieht auf eigene Gefahr des Besuchers. Der Herausgeber, seine Auftraggeber oder Partner sind
                nicht verantwortlich für Schäden, wie direkte, indirekte, zufällige, vorab konkret zu bestimmende oder
                Folgeschäden, die angeblich durch den Besuch dieser Website entstanden sind und übernehmen hierfür
                folglich keine Haftung.
              </p>
              <p>
                Der Herausgeber übernimmt ebenfalls keine Verantwortung und Haftung für die Inhalte und die
                Verfügbarkeit von Website Dritter, die über externe Links dieser Website erreichbar sind. Für den Inhalt
                der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Der Herausgeber distanziert
                sich damit ausdrücklich von allen Inhalten Dritter, die möglicherweise straf- oder haftungsrechtlich
                relevant sind oder gegen die guten Sitten verstossen.
              </p>
              <p>&nbsp;</p>
              <h2>Änderungen</h2>
              <p>
                Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils
                aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer
                Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail
                oder auf andere geeignete Weise informieren.
              </p>
              <p>&nbsp;</p>
              <h2>Fragen an den Datenschutzbeauftragten</h2>{" "}
              <p>
                Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt
                an die für den Datenschutz zu Beginn der Datenschutzerklärung aufgeführten, verantwortlichen Person in
                unserer Organisation.
              </p>
              <p>&nbsp;</p>
              <br />
              Quelle:{" "}
              <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">
                SwissAnwalt
              </a>
              <p />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
