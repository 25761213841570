import { JSONSchema7 } from "json-schema";

export const FormSettingsSchema: JSONSchema7 = {
  type: "object",
  properties: {
    formSchema: {
      title: "Formular-Schema",
      type: "object",
      required: ["formSchema", "uiSchema"],
      properties: {
        formSchema: {
          type: "string",
          title: "Formular-Schema",
          description: "Definiert die Struktur des Formulars. Nur relevant, wenn die Quelle intern ist."
        },
        uiSchema: {
          type: "string",
          title: "UI-Schema",
          description: "Definiert das Aussehen der Felder des Formulars. Nur relevant, wenn die Quelle intern ist."
        },
        emailConfirmationField: {
          type: "string",
          title: "Feldname der E-Mail-Adresse für das Bestätigungs-E-mail",
          description:
            "Geben Sie die Namen des Formularfeldes der E-Mail-Adresse an, falls bei einer erfolgreichen Anmeldung eine E-Mail and die Person gesendet werden soll, welche die Anmeldung getätigt hat. (z.B. Emailadresse der Eltern). Falls noch kein Feld vorhanden ist, können Sie unter Formularfelder eines hinzufügen."
        }
      }
    }
  }
};
