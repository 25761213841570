import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ButtonRow from "../common/button/ButtonRow";
import { DeleteButton, PrimaryButton } from "../common/button/Buttons";
import { useLimits } from "../../hooks/useLimits";
import FirebaseService from "../../actions/firebase/FirebaseActions";
import { useAuthentication } from "../../hooks/useAuthentication";
import { ContentLayout } from "../common/Layout";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

const Limits: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();
  const userId = props.match.params.userId;

  const [limits, limitsLoading, limitsError] = useLimits(userId);
  useAuthentication(props.isAuthenticated);

  if (limitsError) return <p>Fehler beim Laden der Daten</p>;
  else {
    return (
      <ContentLayout siteTitle={"Spielgruppe Online - Angebot"} title={"Angebot"} isLoading={limitsLoading}>
        <ButtonRow>
          <PrimaryButton
            className={"m-1"}
            onClick={() => {
              history.push(`/user/${userId}/limits/create`);
            }}>
            Neues Angebot
          </PrimaryButton>
        </ButtonRow>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Formularfeld</th>
              <th>Anzahl Plätze gesamt</th>
              <th>Anzahl Plätze belegt</th>
              <th>Preis</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {limits.map((limit, index) => {
              return (
                <tr key={index}>
                  <td>{limit.property}</td>
                  <td>{limit.limit}</td>
                  <td>{limit.taken}</td>
                  <td>{limit.price ? `CHF ${limit.price}` : ""}</td>
                  <td>
                    <OverlayTrigger
                      transition={false}
                      key={"edit"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip className={"webtie-tooltip"} id={"tooltip-admin"}>
                          Bearbeiten
                        </Tooltip>
                      }>
                      <PrimaryButton
                        className={"m-1"}
                        onClick={() => {
                          history.push(`/user/${userId}/limits/${limit.id}`);
                        }}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </PrimaryButton>
                    </OverlayTrigger>
                    <OverlayTrigger
                      transition={false}
                      key={"delete"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip className={"webtie-tooltip"} id={"tooltip-admin"}>
                          Löschen
                        </Tooltip>
                      }>
                      <DeleteButton
                        className={"m-1"}
                        onClick={() => {
                          if (window.confirm("Limitierung wirklich löschen?")) {
                            FirebaseService.deleteLimit(userId, limit.id);
                          }
                        }}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </DeleteButton>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ContentLayout>
    );
  }
};

export default Limits;
