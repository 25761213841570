import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { Col, Row } from "react-bootstrap";

import { NumberOfRegistrationsChart } from "./NumberOfRegistrationsChart";
import { RegistrationsPerLimitChart } from "./RegistrationsPerLimitChart";
import { useLimits } from "../../hooks/useLimits";
import { useRegistrations } from "../../hooks/useRegistrations";
import { useAuthentication } from "../../hooks/useAuthentication";
import DashboardCard from "./DashboardCard";
import { useAuthUser } from "../../hooks/useAuthUser";
import { EmailVerifiedNotification } from "../common/EmailVerifiedNotification";
import { ContentLayout } from "../common/Layout";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

const Dashboard: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  useAuthentication(props.isAuthenticated);
  const user = useAuthUser();
  const [registrations, registrationsLoading, registrationsError] = useRegistrations(userId);
  const [limits, limitsLoading, limitsError] = useLimits(userId);

  const isLoading = registrationsLoading || limitsLoading;
  const isError = registrationsError || limitsError;

  if (isError) return <p>Fehler beim Laden der Daten</p>;
  else {
    return (
      <ContentLayout siteTitle={"Spielgruppe Online - Dashboard"} title={"Dashboard"} isLoading={isLoading}>
        <EmailVerifiedNotification className={"mt-5"} user={user} />
        <Row className={"justify-content-center py-3"}>
          {limits.map((limit) => {
            return (
              <Col xs={6} sm={6} lg={4} style={{ marginTop: 30 }} key={limit.property}>
                <DashboardCard title={limit.property}>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <RegistrationsPerLimitChart key={limit.property} width={150} limit={limit} />
                  </div>
                </DashboardCard>
              </Col>
            );
          })}
        </Row>
        <div className="mt-3">
          <h2 className={"my-4"}>Anmeldungen</h2>
          <Row className={"py-3"}>
            <NumberOfRegistrationsChart registrations={registrations} />
          </Row>
        </div>
      </ContentLayout>
    );
  }
};

export default Dashboard;
