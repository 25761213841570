import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";

import { useUserData } from "../../hooks/useUserData";
import { useAuthentication } from "../../hooks/useAuthentication";
import { FormBuilder } from "./FormBuilder";
import { ContentLayout } from "../common/Layout";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

export const Form: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  const [userData, isLoading, isError] = useUserData(userId);
  useAuthentication(props.isAuthenticated);

  if (isError) return <p>Fehler beim Laden der Daten</p>;
  return (
    <ContentLayout title={"Formular"} siteTitle={"Spielgruppe Online - Formular"} isLoading={isLoading}>
      <FormBuilder userId={userId} formSettings={userData?.settings?.formSettings} />
    </ContentLayout>
  );
};
