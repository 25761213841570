import { FunctionComponent } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import WebtieLogo from "../webtie_logo.svg";

export const Footer: FunctionComponent = () => {
  return (
    <Col className={"footer pt-5 mt-5"}>
      <Container>
        <Row>
          <Col md={4} sm={12}>
            <div className="footer-item text-left">
              <h4>Weitere Links</h4>
              <p>
                <a href="https://www.webtie.ch" target={"_blank"}>
                  webtie.ch
                </a>
                <br />
                <a href="https://www.webtie.ch/#contact" target={"_blank"}>
                  Kontakt aufnehmen
                </a>
                <br />
                <Link to="/datenschutz">Datenschutz</Link>
                <br />
              </p>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div className="footer-item text-left">
              <h4>Kontaktangaben</h4>
              <p>
                <a href={"https://www.webtie.ch"} target={"_blank"}>
                  webtie.ch
                </a>
                <br />
                Mauro Stehle / Sven Humbel
                <br />
                Bahnhofstrasse 6a
                <br />
                5210 Windisch
              </p>
            </div>
          </Col>
          <Col md={4} sm={12} className={"footer-logo-div text-right"}>
            <img
              width={250}
              height={"auto"}
              alt={"Logo von webtie.ch"}
              src={WebtieLogo}
              className={"img-fluid footer-logo"}
            />
          </Col>
        </Row>
        <hr className="half-rule" />
        <Row>
          <Col className={"col-12 sub-footer"}>
            <p className={"text-right"}>&#169; {new Date().getFullYear()} webtie.ch. All rights reserved</p>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};
