import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FunctionComponent } from "react";

interface Props {
  style?: CSSProperties;
}

export const SpielgruppePlusIcon: FunctionComponent<Props> = ({ style }: Props) => (
  <FontAwesomeIcon icon={faCrown} style={style} />
);
