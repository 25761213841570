import React, { FunctionComponent } from "react";
import { CSVLink } from "react-csv";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Registration from "../../models/registration/Registration";
import { PrimaryButton } from "../common/button/Buttons";
import { SubscriptionType } from "../../models/subscription/SubscriptionType";
import Limit from "../../models/limit/Limit";
import { trackEvent } from "../../helper/analytics";

interface Props {
  registrations: Registration[];
  limits: Limit[];
  properties: string[];
  className?: string;
  subscriptionType?: SubscriptionType;
}

const ExportAsCsvButton: FunctionComponent<Props> = ({
  registrations,
  properties,
  className,
  subscriptionType
}: Props) => {
  const data = registrations.map((registration) => registration.formData);
  const exportProperties = [...properties, "Preis"];

  const button = (
    <PrimaryButton className={className} variant={"primary"} disabled={subscriptionType === undefined}>
      {!subscriptionType && <FontAwesomeIcon icon={faCrown} />} Anmeldungen Exportieren
    </PrimaryButton>
  );

  if (!data) {
    return <></>;
  }
  if (!subscriptionType) {
    return button;
  }

  return (
    <CSVLink
      separator={";"}
      headers={exportProperties}
      data={data}
      onClick={() => trackEvent("csv-export")}
      filename={`Anmeldungen_${new Date().toISOString().slice(0, 10)}.csv`}>
      {button}
    </CSVLink>
  );
};

export default ExportAsCsvButton;
