import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";

import { PrimaryButton } from "../common/button/Buttons";
import { useUsers } from "../../hooks/useUsers";
import { useAuthentication } from "../../hooks/useAuthentication";
import { ContentLayout } from "../common/Layout";

interface MatchParams {
  userId: string;
}
interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}
const Admin: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();
  const [users, usersLoading, usersError] = useUsers();
  useAuthentication(props.isAuthenticated);

  if (usersError) return <p>Fehler beim Laden der Daten</p>;
  return (
    <ContentLayout title={"Adminzentrale"} siteTitle={"Spielgruppe Onine - Adminzentrale"} isLoading={usersLoading}>
      <Row className={"py-5"}>
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>E-Mail</th>
                <td>Aktionen</td>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>{user.id}</td>
                    <td>{user.email}</td>
                    <td>
                      <PrimaryButton
                        className={"m-1"}
                        onClick={() => {
                          history.push(`/user/${user.id}/dashboard`);
                        }}>
                        Dashboard
                      </PrimaryButton>
                      <PrimaryButton
                        className={"m-1"}
                        onClick={() => {
                          history.push(`/user/${user.id}/registrations`);
                        }}>
                        Anmeldungen
                      </PrimaryButton>
                      <PrimaryButton
                        className={"m-1"}
                        onClick={() => {
                          history.push(`/user/${user.id}/limits`);
                        }}>
                        Angebot
                      </PrimaryButton>
                      <PrimaryButton
                        className={"m-1"}
                        onClick={() => {
                          history.push(`/user/${user.id}/form`);
                        }}>
                        Form
                      </PrimaryButton>
                      <PrimaryButton
                        className={"m-1"}
                        onClick={() => {
                          history.push(`/user/${user.id}/email`);
                        }}>
                        Email
                      </PrimaryButton>

                      <PrimaryButton
                        className={"m-1"}
                        onClick={() => {
                          history.push(`/user/${user.id}/settings`);
                        }}>
                        Einstellungen
                      </PrimaryButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default Admin;
